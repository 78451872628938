import React from 'react'
import { FiExternalLink } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const About = () => {
    const language = useSelector(state => state.language.lang);

    return (
        <div className='relative px-5 sm:px-12 pt-3 pb-24 sm:pt-10 sm:pb-[70px] text-justify text-black'>
            <h2 className='text-xl uppercase sm:text-3xl lg:text-4xl text-primary font-bold  text-center mb-3 sm:mb-8'>
                A proposito di CHIESI
            </h2>
            <div className='mb-5 sm:mb-6 '>
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    Chiesi è un gruppo biofarmaceutico internazionale orientato alla ricerca che sviluppa e commercializza soluzioni terapeutiche innovative nel campo della salute respiratoria, delle malattie rare e delle cure specialistiche.
                </p>
            </div>
            <div className="mb-5 sm:mb-6">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    Per realizzare la propria missione di migliorare la qualità di vita delle persone, il Gruppo agisce in maniera responsabile non solo verso i pazienti, ma anche per le comunità in cui opera e per l’ambiente.

                    Avendo adottato lo status giuridico di Società Benefit in Italia, negli Stati Uniti e in Francia, l’impegno di Chiesi a creare valore condiviso per la società nel suo complesso è legalmente vincolante, e al centro di ogni decisione aziendale.

                    Come B Corp certificata dal 2019, siamo parte di un movimento globale di aziende che rispettano alti standard di impatto sociale e ambientale. L’azienda ha l’obiettivo di raggiungere Zero Emissioni Nette di gas a effetto serra (GHG) entro il 2035.
                </p>


            </div>
            <div className="mb-5 sm:mb-6">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                Chiesi, che vanta oltre 85 anni di esperienza, ha sede a Parma, opera in 31 Paesi, e conta oltre 6.500 collaboratori. Il Centro Ricerche di Parma collabora con altri sei importanti poli di R&S in Francia, Stati Uniti, Canada, Cina, Regno Unito, e Svezia.
                </p>
            </div>
            <div className="mb-5 sm:mb-8">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    Per ulteriori informazioni  <a href="https://www.chiesi.com" className='underline'>www.chiesi.com</a>
                </p>
            </div>
            <div className='mt-8'>
                <Link target="_blank" to={`/copd/${language.value ? language.value : "en"}/cookie-policy`} className="mt-5 flex items-center space-x-3 text-base sm:text-lg lg:text-xl font-bold">
                    <p>
                    Gestione dei Cookie
                    </p>
                    <FiExternalLink />
                </Link>
            </div>

        </div>
    )
}

export default About