export const ArrayCard = [
    {
        "title": <div className=""><div>
            PRESERVA LA TUA
        </div>
            <div>
                 SALUTE
            </div>
        </div>,
        "slogan": "Evita il fumo",
        "path": "/images/cigarette.png",
        "custom_width": null,
        slogan2: true

    },
    {
        "title": "RESTA ATTIVO",
        "slogan": "Fai attività fisica",
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]",

    },
    {
        "title": "RESTA IN FORMA",
        "slogan": "Prediligi una dieta equilibrata",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]"

    },
    {
        "title": <div className="">
            <div>MUOVITI LIBERAMENTE</div>
        </div>,
        "slogan": "Continua a esplorare",
        "path": "/images/aeroplan.png",
        "custom_width": null,
        slogan2: true
    },
    {
        "title": "RESTA CONNESSO",
        "slogan": <div className="leading-tight mt-1">
            <div>Impara a riconoscere, le condizioni</div>
            <div className="">
                mentali
            </div>
        </div>,
        "path": "/images/emojies.png",
        "custom_width": "w-[100%]"
    }
]


export const DetailArrayCard = [
    {
        "title": "PRESERVA LA TUA SALUTE",
        "slogan": "Evita il fumo",
        "path": "/images/cigarette.png",
        "custom_width": null,
        "custom_classes": "lg:mt-12",
        "description": <div className='space-y-5 text-justify leading-tight font-light  '>
            <div>
                È ampiamente condiviso che il fumo, compreso l'uso di sigarette elettroniche, <span className="font-medium">è dannoso sia per la propria salute sia per il benessere di coloro che ti circondano</span>.
                Tuttavia, per le persone con malattie respiratorie <span className="font-medium">il fumo può essere particolarmente dannoso poiché è un importante fattore che contribuisce allo sviluppo ed al peggioramento della BPCO</span>. La ricerca ha dimostrato che <span className="font-medium">smettere di fumare può ridurre significativamente il rischio di ospedalizzazione e mortalità tra i pazienti con BPCO</span>.<sup>1</sup>
            </div>

            <div>
                <span className="font-medium">Se ritieni di aver bisogno di supporto per smettere di fumare, ci sono varie strade che puoi esplorare</span>.
                Sono stati riconosciuti come particolarmente efficaci 3 tipi di intervento: <span className="font-medium">l’assistenza psicologica</span>, <span className="font-medium">l’aiuto da parte di amici e familiari</span> durante le terapie anti-fumo e <span className="font-medium">il supporto sociale al di fuori delle terapie</span>.<sup>2</sup>
            </div>
        </div>,
        "references":
            <ul className='list-decimal'>
                <li>
                    Global Allergy and Airways Patients Platform: <span className='underline'><a href='https://it.gaapp.org/diseases/copd/copd-and-smoking/' target='_blank' >https://it.gaapp.org/diseases/copd/copd-and-smoking/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 111
                </li>
            </ul>


    },
    {
        "title": "RESTA ATTIVO",
        "slogan": "Fai attività fisica",
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]", //for below line
        "custom_classes": "lg:mt-12",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    È ampiamente noto che anche una <span className="font-medium">attività fisica di intensità lieve o moderata può dare benefici concreti alle persone di tutte le età e condizioni</span>.<sup>1 </sup>
                    Non serve spingersi al limite ogni giorno per prendersi cura del proprio sistema respiratorio.
                    La ricerca dimostra che gli individui con BPCO spesso fanno meno attività fisica<sup>2</sup>,
                    nonostante questa <span className="font-medium">contribuisca attivamente al benessere generale</span>.
                    È importante, quindi, <span className="font-medium">rimanere attivi per mantenere una buona salute</span>.
                    Valuta di inserire una <span className="font-medium">piacevole passeggiata quotidiana nella tua routine</span>: sapevi che <span className="font-medium">30 minuti di camminata veloce al giorno possono migliorare la qualità respiratoria</span><sup>3 </sup>
                    nelle persone con BPCO ?
                </div>
                <div>
                    <span className="font-medium">Preferisci le scale</span> all'ascensore, <span className="font-medium">fai un po' di giardinaggio leggero</span> o <span className="font-medium">goditi una rapida nuotata</span>, quando possibile. Quando si pratica attività fisica, <span className="font-medium">è fondamentale farlo correttamente</span>, <span className="font-medium">anche se si tratta di un'attività leggera</span>. Inizia con lo <span className="font-medium">stretching o il riscaldamento</span>, rimani <span className="font-medium">idratato</span>, prenditi il tuo tempo, <span className="font-medium">assicurati che i tuoi farmaci siano sempre a portata di mano</span> e confrontati col tuo medico sulla soluzione migliore per la tua condizione generale.
                </div>
            </div>,
        "references":
            <ul className='list-decimal'>
                <li>
                    European Lung Foundation: <span className='underline'><a href='https://europeanlung.org/it/information-hub/factsheets/vivere-una-vita-attiva-con-la-bpco/' target='_blank' >https://europeanlung.org/it/information-hub/factsheets/vivere-una-vita-attiva-con-la-bpco/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 122
                </li>
                <li>
                    Better Health Channel: <span className='underline'><a href='https://www.betterhealth.vic.gov.au/health/healthyliving/walking-for-good-health' target='_blank' >https://www.betterhealth.vic.gov.au/health/healthyliving/walking-for-good-health</a></span>
                </li>
            </ul>
    },
    {
        "title": "RESTA IN FORMA",
        "slogan": "Prediligi una dieta equilibrata",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className="font-medium">La tua dieta svolge un ruolo cruciale nel contribuire alla salute respiratoria</span>. Il peso corporeo può avere un impatto significativo sulla funzione respiratoria e aggravare i sintomi della BPCO. Inoltre, è importante notare che molti pazienti affetti da BPCO manifestano sintomi di malnutrizione proteico-energetica. <span className="font-medium">La soluzione è mantenere una dieta equilibrata</span>.
                </div>
                <div>
                    Questo include <span className="font-medium">limitare l'assunzione di sale, ridurre il consumo di grassi</span>, <span className="font-medium">consumare adeguate quantità di frutta</span> per vitamine e minerali essenziali e <span className="font-medium">garantire una buona fonte di proteine nei tuoi pasti</span>. Questo approccio contribuirà a mantenere tonici i muscoli respiratori.
                </div>
            </div>,
        "references":
            <ul className=''>
                <li>
                    American Lung Association: <span className='underline'><a href='https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/nutrition' target='_blank' >https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/nutrition/</a></span>
                </li>
            </ul>,
        "reference_length": 1


    },
    {
        "title": "MUOVITI LIBERAMENTE",
        "slogan": "Continua a esplorare",
        "path": "/images/aeroplan.png",
        "custom_width": null,
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className='font-medium'>Avere una condizione respiratoria cronica non dovrebbe ostacolare la tua capacità di viaggiare<sup className='font-light'>1</sup>, visitare i tuoi cari o goderti le vacanze</span>. È, tuttavia, fondamentale fare qualche ricerca in anticipo. 
                    <span className='font-medium'>Considera sempre il clima della tua destinazione</span>, tenendo conto della <span className="font-medium">temperatura</span> (poiché i sintomi della BPCO possono peggiorare in ambienti caldi), <span className="font-medium">dell'altitudine</span> (le altitudini più elevate hanno livelli di ossigeno più bassi, quindi consulta il medico prima di recarti in zone di montagna) e <span className="font-medium">la qualità dell'aria in termini di inquinamento e allergeni</span><sup>2</sup>.
                </div>
                <div>
                    È opportuno notare che, anche se l'ossigenazione a riposo è normale a livello del mare, è possibile sperimentare una sua riduzione quando si viaggia in aereo<sup>3</sup>.
                    Pertanto, <span className="font-medium">se prevedi di volare</span>, <span className="font-medium">consulta il tuo medico</span> e <span className="font-medium">informa la compagnia aerea</span> sulla tua condizione, al momento dell'acquisto del biglietto: possono garantirti <span className="font-medium">un volo sicuro e confortevole</span>, con sistemazioni adeguate.
                </div>
            </div>,
        "references":
            <ul className='list-decimal'>
                <li>
                    COPD Foundation: <span className='underline'><a href='https://www.copdfoundation.org/Learn-More/I-am-a-Person-with-COPD/Traveling-with-COPD.aspx' target='_blank' >https://www.copdfoundation.org/Learn-More/I-am-a-Person-with-COPD/Traveling-with-COPD.aspx</a></span>
                </li>
                <li>
                    European Lung Foundation: <span className='underline'><a href='https://europeanlung.org/en/information-hub/factsheets/living-an-active-life-with-copd/' target='_blank' >https://europeanlung.org/en/information-hub/factsheets/living-an-active-life-with-copd/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 81
                </li>
            </ul>
    },
    {
        "title": "RESTA CONNESSO",
        "slogan": "Impara a riconoscere le condizioni mentali",
        "path": "/images/emojies.png",
        "custom_width": "w-[100%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className="font-medium">Depressione</span> e <span className="font-medium">ansia sono più comuni</span> tra gli individui con diagnosi di BPCO, rispetto alla popolazione generale. Queste condizioni possono essere difficili da riconoscere, accettare e trattare, in particolare all'inizio. <span className="font-medium">Il primo passo cruciale è riconoscerle</span>.
                    Se credi che essere affetto da BPCO stia avendo effetti sul tuo benessere mentale, <span className="font-medium">parlane col tuo medico</span>. Ricorda che <span className="font-medium">la salute mentale è importante quanto la salute fisica</span> e il medico può aiutarti a identificare eventuali problemi e sviluppare un piano di trattamento, se necessario.
                </div>
                <div>
                    <span className="font-medium">È essenziale riconoscere che non si è soli</span> nell’affrontare la BPCO. Cerca il supporto dei tuoi cari e non esitare a condividere le tue emozioni.
                    Anche <span className="font-medium">l’essere in contatto con altri pazienti affetti da BPCO può essere utile</span>, poiché è possibile condividere esperienze e imparare gli uni dagli altri. <span className="font-medium">Dai un’occhiata ai gruppi di pazienti locali</span>, per ulteriori informazioni e supporto.
                </div>
            </div>,

        "references":
            <ul className=''>
                <li>
                    American Lung Association: <span className='underline'><a href='https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/coping-with-emotions/' target='_blank' >https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/coping-with-emotions</a></span>
                </li>

            </ul>,
        "reference_length": 1

    }
]