import React from 'react'

const MainRiskFact = () => {
    return (
        <><div className='pb-8 pt-4'>
            <div className='text-primary font-bold pb-4'>
                What are the main risk factors associated with asthma?<sup>1</sup>
            </div>
            <ul className='list-disc'>
                <li>
                    Asthma is more <span className='font-medium'>likely if other family members also have asthma</span> – particularly a close relative, such as a parent or sibling.
                </li>
                <li>Asthma is <span className='font-medium'>more common in people who have other allergic conditions</span>, such as eczema and rhinitis (hay fever).</li>
                <li><span className='font-medium'>Urbanization is associated with increased asthma prevalence</span>, probably due to multiple lifestyle factors.</li>
                <li><span className='font-medium'>Events in early life affect</span> the developing lungs and can increase the risk of asthma. These include <span className='font-medium'>low birth weight, prematurity, exposure to tobacco smoke</span> and other <span className='font-medium'>air pollution</span> sources, and <span className='font-medium'>viral respiratory infections</span>.</li>
                <li><span className='font-medium'>Exposure to a range of environmental allergens</span> and irritants is  also thought to increase the risk of asthma, including indoor and outdoor <span className='font-medium'>air pollution, house
                dust mites, molds,</span> and occupational exposure to <span className='font-medium'>chemicals, fumes, or dust</span>.</li>
                <li>Children and adults who are <span className='font-medium'>overweight or obese</span> are at a greater risk of asthma.</li>
            </ul>
        </div><hr className=' bg-black text-black' style={{ height: '2px' }} /><div className='py-8 text-sm '>
                <p className='font-bold uppercase'>references:</p>
                <ul className=' list-decimal italic text-xs px-3 space-y-1 pt-1 break-all '>
                    <li>Global burden of 369 diseases and injuries in 204 countries and territories, 1990–2019: a systematic analysis for the Global Burden of Disease Study 2019. Lancet. 2020;396(10258):1204-22</li>


                </ul>
            </div></>
        
    )
}

export default MainRiskFact