import React, { useEffect } from 'react'

const Card = ({ setActive,item }) => {

    return (
        <>
            {
                    <div className={` h-[200px] w-full p-6 cursor-pointer text-white bg-primary`}>
                        <div className='flex w-full h-full flex-col justify-between  hover:scale-105 delay-75 transition-all '>
                            <div className=' '>
                                <h1 className='font-bold text-[24px] lg:text-[25px] xl:text-[26px] 2xl:text-[28px]'>{item.title}</h1>
                                <p className={`text-[16px]`}>
                                    {item.slogan}
                                </p>
                                
                            </div>
                            <div>
                                {/* <img src={item.path} className={`w-24 ml-auto ${item.slogan2?"mt-2 sm:mt-0 md:mt-2 lg:mt-0 xl:mt-2 ":""} `} /> */}
                                <img src={item.path} className={`w-24 - ml-auto ${item.slogan2?"mt-2 sm:mt-0 md:mt-2 lg:mt-0 xl:mt-2 ":""}${(item.path =="/images/Connected.png" || item.path =="/images/cigarette.png") && "-mt-6 w-28"} `} />
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Card