import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ENGLISH_LANGUAGE, FRENCH_LANGUAGE, ITALIAN_LANGUAGE, DUTCH_LANGUAGE } from '../../../constants'
import { set_lang } from '../../../redux/slices/languageSlice'
import { setLang } from '../../../utils'
import ModalVideo from 'react-modal-video';
import "react-modal-video/css/modal-video.min.css"
import {AiOutlineSound} from "react-icons/ai";
import { useNavigate } from 'react-router-dom'

const LangSelect = ({currentVideo}) => {
    const lang = useSelector(state => state.language.lang)
    const tab = useSelector(state => state.tab.tab)

    const navigate=useNavigate()
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768)
        }

        // Attach the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, [])


    const handlLanguage=(language)=>{
        dispatch(set_lang(language))
        setLang(language)
        navigate(`/copd/${language.value}/${tab}`)
    }

    return (
        <>
        <ModalVideo
                channel='custom'
				isOpen={isOpen}
                url={
                currentVideo == 0?
                "/videos/carousel/223_chiesi 23k_landing desktop ENG.mp4"
                :
                currentVideo == 1?
                `/videos/carousel/${
                    lang.value===ENGLISH_LANGUAGE.value?
                    "223_chiesi 23k_1920x1080 ENG.mp4"
                    :
                    lang.value===FRENCH_LANGUAGE.value?
                    "french/223_chiesi 23k_1920x1080b FRA.mp4"
                    :
                    lang.value===ITALIAN_LANGUAGE.value?
                    "Italian/223_chiesi 23k_1920x1080b ITA.mp4"
                    :
                    ""
                    }`
                :""

                }
				onClose={() => setOpen(false)} 
        />
            <div className='absolute top-4 md:top-4 right-2 md:right-4 lg:right-40 z-[10001] px-8 py-1 md:py-3 rounded-xl bg-white  bg-opacity-25 w-auto flex items-center justify-end space-x-3'>
                <button onClick={() => handlLanguage(ENGLISH_LANGUAGE)}  className={`focus:outline-none text-base sm:text-lg font-bold text-black ${lang.value === ENGLISH_LANGUAGE.value ? "font-bold" : "font-light"}`}>
                    EN
                </button>
                {/* <button onClick={() => handlLanguage(ITALIAN_LANGUAGE)} className={`focus:outline-none text-base sm:text-lg font-bold text-black ${lang.value === ITALIAN_LANGUAGE.value ? "font-bold" : "font-light"}`}>
                    IT
                </button>
                <button onClick={() => handlLanguage(FRENCH_LANGUAGE)} className={`focus:outline-none text-base sm:text-lg font-bold text-black ${lang.value === FRENCH_LANGUAGE.value ? "font-bold" : "font-light"}`}>
                    FR
                </button> */}
                <button onClick={() => handlLanguage(DUTCH_LANGUAGE)} className={`focus:outline-none text-base sm:text-lg font-bold text-black ${lang.value === DUTCH_LANGUAGE.value ? "font-bold" : "font-light"}`}>
                    DE
                </button>
                {
                    currentVideo !== 2 && currentVideo !==0 ?
                        <button onClick={() => {
                            setOpen(true)
                        }} className={`focus:outline-none text-base sm:text-lg font-bold text-black ${lang.value === ITALIAN_LANGUAGE.value ? "font-bold" : "font-light"}`}>
                            <AiOutlineSound />
                        </button>
                    :
                        null
                }
            </div>
        </>
    )
}

export default LangSelect