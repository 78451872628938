import React from 'react'

const AreYouCareGiver = () => {
    return (
        <div className='mt-8 pb-8 px-5 sm:px-12 leading-tight font-light bg-primary text-[19px]  text-white '>
            <div className='flex  items-center space-x-5'>
                <img src='/images/peoples.png' className='w-32' />
                <div>
                    <h1 className='text-base sm:text-lg lg:text-xl font-bold uppercase'> Sei un caregiver?</h1>
                    <p className='font-light'>Ecco alcune semplici cose che puoi fare</p>
                </div>

            </div>
            <div className='-mt-4'>
                <hr className=' bg-white  text-white' style={{ height: '1px' }} />
            </div>


            <div className=' -mt-1 pb-3 text-justify '>
                <div className='mt-8 '>
                    <span className='font-medium'>Prendersi cura di una persona con BPCO non è un'impresa semplice, soprattutto se si tratta di qualcuno che ami</span>. La tua vita potrebbe cambiare, forse anche le tue abitudini e la tua routine e questo potrebbe farti sentire nervoso, anche depresso, ansioso.
                </div>
                <div className='mt-4'>
                    La soluzione per meglio prenderti cura di qualcuno affetto da una malattia può essere <span className='font-medium'>prenderti cura prima di tutto di te stesso</span>, quando possibile. <span className='font-medium'>Trova del tempo per te, ricarica le tue batterie</span>. Se ti senti stressato, <span className='font-medium'>chiedi aiuto, coinvolgi un tuo caro</span>, un amico e trova il tempo e l'energia per <span className='font-medium'>recuperare la forza</span> e la <span className='font-medium'>volontà di continuare a sostenere la persona</span> a cui stai dando sostegno.
                    <div className='font-medium mt-4'>Il tuo benessere è fondamentale per il suo benessere, quindi prendersi cura di sé stessi è un modo per prendersi cura degli altri.
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AreYouCareGiver