import React from 'react'

const WhatIsCOPD = () => {
    return (
        <div className='pt-4'>
            <p>
            Laut den neuesten Schätzungen <span className='font-medium'>betrifft COPD mehr als 300 Millionen Menschen weltweit</span>, die Prävalenz und Belastung durch COPD werden in den kommenden Jahrzehnten aufgrund einer Kombination aus fortgesetzter Exposition gegenüber <span className='font-medium'>COPD-Risikofaktoren und der Alterung der Weltbevölkerung voraussichtlich zunehmen</span>.
                <sup>1,2</sup>
            </p>
            <p>
            Es wurde auch ein Anstieg der Prävalenz bei Frauen beobachtet, was teilweise auf den zunehmenden Tabakkonsum bei Frauen weltweit und die Exposition gegenüber Biomassebrennstoffen zurückzuführen ist.<sup>3</sup> Laut den neuesten Schätzungen der WHO (Weltgesundheitsorganisation) ist COPD die dritthäufigste Todesursache weltweit und verursachte 2019 3,23 Millionen Todesfälle.<sup>1,2,4</sup>
            </p>
            <p>
            Eine weit verbreitete Unterdiagnose und Fehldiagnose führt dazu, dass Patient:innen keine oder falsche Behandlungen erhalten. Eine angemessene und frühere Diagnose von COPD kann erhebliche Auswirkungen auf die öffentliche Gesundheit haben.<sup>2</sup>
            </p>
            <div className='pt-5'>
                <span className='font-bold'>COPD IM ÜBERBLICK</span>
                <div className='pt-3'>
                    <span className='text-primary font-bold'>COPD steht für Chronisch Obstruktive Lungenerkrankung</span>, was bedeutet:<sup>5</sup>

                    <ul className='list-disc py-2 px-9'>
                        <li><span className='text-primary font-bold'>Chronisch</span> - es ist eine langfristige Erkrankung</li>
                        <li><span className='text-primary font-bold'>Obstruktiv</span> - die Atemwege sind verengt, was den Luftfluss während der Ausatmung blockiert oder behindert</li>
                        <li><span className='text-primary font-bold'>Pulmonal</span> - es betrifft die Lunge</li>
                        <li><span className='text-primary font-bold'>Disease</span> - Erkrankung</li>
                    </ul>

                    <div className='py-8 space-y-4 text-light'>
                        <div className=''>
                        Der Begriff COPD wird verwendet, um eine <span className='font-medium'>Gruppe von Lungenerkrankungen zu beschreiben</span>, die das Ausatmen der Luft aus den <span className='font-medium'>Lungen erschweren. Dies geschieht, wenn das Lungengewebe beschädigt ist und die Atemwege verengt sind</span>. Schleim kann auch teilweise die Atemwege blockieren und die Wände der <span className='font-medium'>Atemwege können anschwellen und sich entzünden</span>. All diese Faktoren <span className='font-medium'>erschweren den Luftaustausch während des Atmens</span> und die Lungen sind weniger in der Lage, Sauerstoff aufzunehmen und Kohlendioxid abzugeben.<sup>5</sup>
                        </div>
                        <div>
                        <span className='font-medium'>Die Hauptsymptome von COPD sind Atemnot (Dyspnoe) und/oder Husten mit oder ohne Schleimproduktion</span>; Menschen mit COPD können <span className='font-medium'>akute Ereignisse erleben, die durch erhöhte Atemsymptome gekennzeichnet sind und als Exazerbationen bezeichnet werden</span>, die ihren Gesundheitszustand und ihre Prognose beeinflussen und <span className='font-medium'>spezifische präventive und therapeutische Maßnahmen erfordern</span>.<sup>2</sup>
                        </div>
                        <div>
                        Patient:innen mit <span className='font-medium'>COPD haben häufig andere Begleiterkrankungen, die ebenfalls ihren klinischen Zustand und ihre Prognose beeinflussen und spezifische Behandlungen erfordern</span>. Diese Begleiterkrankungen können eine akute Exazerbation nachahmen und/oder verschlimmern. Die häufigsten chronischen Erkrankungen, die mit COPD verbunden sind, umfassen Herz-Kreislauf- und Atemwegserkrankungen, Osteoporose, Muskelschwund, Stoffwechsel- und neuropsychiatrische Störungen, chronische Nierenerkrankungen, gastroösophagealen Reflux und andere.
                        </div>
                        <div>
                        <span className='font-medium'>Begleiterkrankungen treten bei Menschen mit COPD früher im Leben auf und bleiben oft unentdeckt</span>, falsch diagnostiziert, unbehandelt oder falsch behandelt. Die häufigen Morbiditäten bei patient:innen mit COPD treten nicht zufällig auf, sondern sind das Ergebnis von Syndemien, die als das Auftreten von Krankheitsclustern mit gemeinsamen Risikofaktoren und biologischen Interaktionen definiert sind, die die Prognose und Belastung durch die Krankheit für Einzelpersonen und die Gesellschaft verschärfen.<sup>6</sup>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WhatIsCOPD