import React from 'react'
import {BiLoaderAlt} from "react-icons/bi";
import {motion} from "framer-motion";

export const Loading = () => {
    return (
        <div className="flex justify-center items-center my-12">
            <span className="text-5xl text-green-secondary">
                <BiLoaderAlt className="animate-spin"/>
            </span>
        </div>
    )
}

export default Loading

export const FullScreenLoading = () => {
    return (
        <div className="loader-screen left-0 top-0 fixed h-screen w-screen bg-white flex items-center justify-center" style={{zIndex: 10000}}>
            <h2 className="animate-spin text-7xl text-green-secondary"><BiLoaderAlt /></h2>
        </div>
    )
} 

export const LogoLoading = () => {
    return (
        <div className="loader-screen left-0 top-0 fixed h-screen w-screen bg-white flex flex-col items-center justify-center" style={{zIndex: 10000}}>
            <motion.div 
            initial={{
                opacity: 0,
                top:"100px",
                scale: .4
            }}
            animate={{
                opacity: 1,
                top:0,
                scale: 1
            }}
            transition={{
                duration: .5
            }}
            className="relative flex flex-col items-center justify-center">
                <motion.img 
                src="/images/logo.png" alt="Forfetarria Logo" className='max-w-[300px] relative mx-auto block mb-12' />
                <h2 
                    className="animate-spin text-7xl text-green-secondary"
                >
                    <BiLoaderAlt />
                </h2>
            </motion.div>
        </div>
    )
}
