import * as React from "react";


const SoundIcon = (props) => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   xmlnsXlink="http://www.w3.org/1999/xlink"
  //   x="0px"
  //   y="0px"
  //   viewBox="0 0 2000 2000"
  //   style={{
  //     enableBackground: "new 0 0 2000 2000",
  //   }}
  //   xmlSpace="preserve"
  //   {...props}
  // >
  //   <style type="text/css">{"\n\t.st0{fill:#FFFFFF;}\n"}</style>
  //   <g />
  //   <g>
  //     {/* <g className="sound-icon-rounded-text" style={{
  //       rotate: "-55deg",
  //       translate: "-50%, -50%",
  //       transformOrigin: 'center' 
  //     }} >
  //       <path
  //         className="st0"
  //         d="M1137.51,594.23l12.75,4.65c14.54,5.29,26.06,0.68,30.81-12.36c4.75-13.04-1.09-23.98-15.64-29.28 l-20.13-7.33l-22.68,62.25l7.39,2.69L1137.51,594.23z M1162.75,564.33c5.32,1.94,8.94,4.59,10.74,7.87 c1.77,3.22,1.83,7.16,0.17,11.72c-1.66,4.55-4.24,7.54-7.67,8.86c-3.49,1.35-7.95,1.06-13.28-0.89l-12.66-4.61l10.04-27.56 L1162.75,564.33z"
  //       />
  //       <polygon
  //         className="st0"
  //         points="1210.91,651.11 1214.39,644.37 1185.57,629.48 1212.5,577.36 1205.52,573.75 1175.11,632.61  "
  //       />
  //       <path
  //         className="st0"
  //         d="M1218.13,655.58l14.61-9.41l26,18.19l-3.75,17l6.82,4.77l15.48-70.02l-5.59-3.91l-60.46,38.56L1218.13,655.58 z M1267.77,623.76l-7.29,32.78l-20.94-14.65L1267.77,623.76z"
  //       />
  //       <polygon
  //         className="st0"
  //         points="1279.3,702.81 1295.72,684.63 1342.32,670.45 1335.66,664.43 1298.46,675.92 1306.03,637.69  1299.31,631.62 1289.83,679.56 1273.54,697.6  "
  //       />
  //       <polygon
  //         className="st0"
  //         points="1414.84,764.15 1361.6,780.57 1393.84,734.22 1389.87,728.58 1359.16,736.85 1335.46,743.15  1368.92,698.72 1364.18,691.96 1322.41,747.76 1326.39,753.42 1381.33,738.79 1348.93,785.55 1352.85,791.14 1419.58,770.91  "
  //       />
  //       <rect
  //         x={1364.71}
  //         y={801.64}
  //         transform="matrix(0.8922 -0.4516 0.4516 0.8922 -213.1407 718.1593)"
  //         className="st0"
  //         width={66.25}
  //         height={7.86}
  //       />
  //       <polygon
  //         className="st0"
  //         points="1383.08,853.13 1385.91,860.36 1440.52,838.99 1448.58,859.58 1455.64,856.82 1436.73,808.5  1429.67,811.26 1437.69,831.76  "
  //       />
  //       <polygon
  //         className="st0"
  //         points="1398.4,897.38 1427.6,890.65 1435.51,924.92 1406.31,931.66 1408.08,939.31 1472.62,924.42  1470.85,916.77 1442.9,923.22 1434.99,888.94 1462.95,882.49 1461.18,874.84 1396.64,889.73  "
  //       />
  //       <path
  //         className="st0"
  //         d="M1412.36,976.16l16.21,6.26l0.64,31.72l-15.94,6.99l0.17,8.32l65.68-28.77l-0.14-6.82l-66.78-26.11 L1412.36,976.16z M1436.03,985.4l31.28,12.11l-30.77,13.44L1436.03,985.4z"
  //       />
  //       <path
  //         className="st0"
  //         d="M1418.23,1040.29c-5.33,3.79-8.65,9.83-9.6,17.46c-1.94,15.46,7.3,26.37,24.1,28.48l39.57,4.96l0.96-7.7 l-39.3-4.93c-6.42-0.8-11.22-2.96-14.26-6.4c-2.99-3.39-4.15-7.91-3.46-13.45c0.69-5.54,2.94-9.63,6.67-12.18 c3.79-2.59,8.97-3.49,15.4-2.68l39.3,4.92l0.97-7.71l-39.57-4.96C1430.72,1035.06,1423.54,1036.51,1418.23,1040.29z"
  //       />
  //       <path
  //         className="st0"
  //         d="M1397.6,1117.98c-2.78,9.88-2.11,19.07,1.94,26.59c4.04,7.48,11.16,12.85,20.61,15.51 c3.64,1.03,7.16,1.52,10.53,1.52c14.55,0,26.08-9.32,30.68-25.65l5.79-20.54l-63.76-17.96L1397.6,1117.98z M1457.72,1120.92 l-3.6,12.79c-4.46,15.81-16.64,22.99-31.8,18.72c-7.4-2.09-12.93-6.19-16.01-11.86c-3.09-5.71-3.58-12.81-1.41-20.52l3.65-12.97 L1457.72,1120.92z"
  //       />
  //       <rect
  //         x={1407.82}
  //         y={1145.27}
  //         transform="matrix(0.3827 -0.9239 0.9239 0.3827 -217.2509 2031.6146)"
  //         className="st0"
  //         width={7.86}
  //         height={66.24}
  //       />
  //       <path
  //         className="st0"
  //         d="M1404.91,1197.15c-8.5-4.8-17.81-6.17-26.21-3.86c-8.41,2.31-15.67,8.24-20.44,16.7 c-9.88,17.5-4.5,36.65,13.09,46.59c5.94,3.36,12.06,4.97,17.94,4.97c11.54,0,22.17-6.23,28.71-17.82 C1427.88,1226.24,1422.5,1207.08,1404.91,1197.15z M1411.16,1239.87c-7.73,13.68-22.17,17.64-35.95,9.86 c-13.77-7.78-17.84-22.2-10.11-35.88c7.7-13.63,22.13-17.56,35.9-9.78c6.75,3.81,11.28,9.25,13.11,15.74 C1415.93,1226.28,1414.91,1233.22,1411.16,1239.87z"
  //       />
  //       <rect
  //         x={1318.45}
  //         y={1286.86}
  //         transform="matrix(0.6481 -0.7616 0.7616 0.6481 -513.6484 1469.4385)"
  //         className="st0"
  //         width={29.24}
  //         height={7.22}
  //       />
  //       <path
  //         className="st0"
  //         d="M1273.02,1317.13l14,16.88l-10.45,8.66c-11.91,9.88-13.74,22.15-4.89,32.84c4.36,5.26,9.73,8.14,15.52,8.35 c0.22,0.01,0.44,0.01,0.66,0.01c5.54,0,11.4-2.44,17-7.07l16.5-13.68l-42.28-51L1273.02,1317.13z M1310.47,1362.3l-10.38,8.6 c-8.66,7.18-16.19,7.02-22.41-0.47c-3.09-3.73-4.36-7.46-3.78-11.09c0.59-3.7,3.11-7.4,7.47-11.02l10.38-8.6L1310.47,1362.3z"
  //       />
  //       <polygon
  //         className="st0"
  //         points="1200.86,1368.68 1204.92,1375.08 1232.32,1357.72 1263.72,1407.26 1270.35,1403.06 1234.89,1347.11  "
  //       />
  //       <path
  //         className="st0"
  //         d="M1193.29,1372.7l0.78,17.36l-28.8,13.31l-12.8-11.79l-7.55,3.49l52.7,48.6l6.19-2.86l-2.89-71.63 L1193.29,1372.7z M1195.79,1431.6l-24.65-22.79l23.19-10.72L1195.79,1431.6z"
  //       />
  //       <polygon
  //         className="st0"
  //         points="1129.12,1399.44 1121.72,1401.81 1129.2,1425.14 1118.05,1472.56 1126.59,1469.82 1135.35,1431.89  1164.6,1457.64 1173.23,1454.88 1136.54,1422.6  "
  //       />
  //       <polygon
  //         className="st0"
  //         points="1061.2,1416.56 1054.3,1417.15 1039.34,1472 1015.2,1420.49 1008.39,1421.07 992.35,1488.92  1000.57,1488.22 1013.14,1433.94 1037,1485.11 1043.87,1484.52 1058.62,1430.16 1067.93,1452.42 1080.21,1481.41 1088.43,1480.71  1061.52,1417.32  "
  //       />
  //       <rect
  //         x={940.2}
  //         y={1448.71}
  //         transform="matrix(0.0596 -0.9982 0.9982 0.0596 -534.7484 2337.6357)"
  //         className="st0"
  //         width={66.24}
  //         height={7.86}
  //       />
  //       <polygon
  //         className="st0"
  //         points="939.66,1415.9 931.99,1414.69 922.85,1472.62 901.02,1469.18 899.83,1476.67 951.09,1484.75  952.27,1477.26 930.53,1473.83  "
  //       />
  //       <polygon
  //         className="st0"
  //         points="893.7,1406.87 884.65,1435.44 851.12,1424.82 860.17,1396.25 852.68,1393.88 832.67,1457.03  840.16,1459.4 848.83,1432.05 882.36,1442.67 873.7,1470.02 881.19,1472.4 901.19,1409.25  "
  //       />
  //       <path
  //         className="st0"
  //         d="M805.02,1390.03l-27.75-15.39l1.97-17.3l-7.28-4.04l-8.14,71.24l5.97,3.31l56.14-44.62l-7.36-4.08 L805.02,1390.03z M798.69,1394.98l-26.19,20.96l3.85-33.36L798.69,1394.98z"
  //       />
  //       <path
  //         className="st0"
  //         d="M749.92,1334.93c-6.12-4.66-12.75-6.53-19.19-5.37c-6.42,1.14-12.48,5.26-17.55,11.9L689,1373.17l6.18,4.71 l24.01-31.5c7.84-10.28,17.1-12.19,26.07-5.34c8.97,6.84,9.6,16.27,1.76,26.56l-24.01,31.5l6.18,4.71l24.18-31.72 c5.06-6.64,7.42-13.58,6.82-20.07C759.59,1345.5,756.03,1339.59,749.92,1334.93z"
  //       />
  //       <path
  //         className="st0"
  //         d="M703.3,1295.05c-7.07-7.44-15.32-11.56-23.85-11.92c-8.47-0.35-16.75,3.03-23.86,9.79 c-14.59,13.86-14.72,32.61-0.32,47.76l14.7,15.47l48.03-45.63L703.3,1295.05z M707.09,1310.05l-37.04,35.18l-9.16-9.64 c-5.56-5.85-8.47-12.39-8.42-18.91c0.05-6.48,3.02-12.71,8.59-18c5.44-5.16,11.47-7.73,17.52-7.73c6.66,0,13.33,3.12,19.22,9.32 L707.09,1310.05z"
  //       />
  //       <rect
  //         x={611.04}
  //         y={1272.94}
  //         transform="matrix(0.797 -0.604 0.604 0.797 -640.4588 648.3052)"
  //         className="st0"
  //         width={66.24}
  //         height={7.86}
  //       />
  //       <path
  //         className="st0"
  //         d="M643.88,1214.73c-10.12-17.35-29.38-22.36-46.83-12.19c-17.45,10.18-22.56,29.41-12.44,46.76 c4.9,8.39,12.24,14.22,20.68,16.42c2.53,0.66,5.14,0.98,7.78,0.98c6.15,0,12.47-1.77,18.37-5.21 C648.89,1251.32,654.01,1232.09,643.88,1214.73z M640.3,1238.72c-1.75,6.52-6.2,12.02-12.86,15.91 c-6.69,3.9-13.68,5.07-20.21,3.38c-6.51-1.69-11.98-6.07-15.83-12.66c-7.92-13.57-4.05-28.05,9.61-36.02 c4.78-2.79,9.66-4.14,14.33-4.14c8.68,0,16.61,4.68,21.76,13.5C640.94,1225.28,642.04,1232.21,640.3,1238.72z"
  //       />
  //       <rect
  //         x={583.39}
  //         y={1137.81}
  //         transform="matrix(0.943 -0.3329 0.3329 0.943 -350.1338 261.1136)"
  //         className="st0"
  //         width={7.22}
  //         height={29.23}
  //       />
  //       <path
  //         className="st0"
  //         d="M594.08,1087.13l-21.62,3.62l-2.24-13.38c-1.25-7.45-4.27-13.29-8.74-16.88c-4.52-3.63-10.46-4.96-17.2-3.83 c-6.73,1.13-11.92,4.32-15.01,9.23c-3.05,4.86-4,11.36-2.76,18.81l3.54,21.13l65.32-10.95L594.08,1087.13z M536.24,1096.82 l-2.23-13.29c-1.86-11.09,2.06-17.53,11.66-19.14c1.2-0.2,2.35-0.3,3.43-0.3c7.56,0,12.2,4.89,13.83,14.59l2.23,13.29 L536.24,1096.82z"
  //       />
  //       <polygon
  //         className="st0"
  //         points="585.8,998.83 578.22,999.11 579.44,1031.53 520.81,1033.73 521.11,1041.58 587.31,1039.1  "
  //       />
  //       <path
  //         className="st0"
  //         d="M586.14,990.33l-15.38-8.09l3.04-31.58l16.64-5.1l0.8-8.28l-68.56,20.98l-0.65,6.79l63.31,33.65 L586.14,990.33z M566.14,952.99l-2.44,25.43l-29.67-15.65L566.14,952.99z"
  //       />
  //       <polygon
  //         className="st0"
  //         points="595.45,921.44 597.14,913.86 573.24,908.54 537.94,874.97 535.99,883.73 564.31,910.43  527.31,922.68 525.34,931.52 571.72,916.16  "
  //       />
  //       <polygon
  //         className="st0"
  //         points="617.89,847.85 577.99,807.34 634.66,812.37 637.58,806.19 586.99,758.22 583.46,765.68  624.06,803.83 567.84,798.73 564.89,804.97 587.28,827.57 604.47,845.05 580.54,841.91 549.31,837.94 545.78,845.4 614.93,854.11  "
  //       />
  //       <rect
  //         x={623.97}
  //         y={726.89}
  //         transform="matrix(0.5532 -0.833 0.833 0.5532 -352.5812 862.6011)"
  //         className="st0"
  //         width={7.86}
  //         height={66.23}
  //       />
  //       <polygon
  //         className="st0"
  //         points="631.07,712.34 676.57,749.37 681.47,743.35 635.98,706.32 649.94,689.17 644.05,684.38  611.29,724.63 617.17,729.42  "
  //       />
  //       <polygon
  //         className="st0"
  //         points="707.56,714.26 687.33,692.14 713.3,668.4 733.52,690.52 739.32,685.22 694.62,636.32 688.82,641.62  708.18,662.8 682.21,686.54 662.85,665.36 657.05,670.67 701.76,719.56  "
  //       />
  //       <path
  //         className="st0"
  //         d="M766.76,646.17l27.28-16.2l13.95,10.42l7.15-4.25l-57.42-42.95l-5.87,3.49l10.22,70.97l7.24-4.3 L766.76,646.17z M765.67,638.21l-4.89-33.19l26.87,20.14L765.67,638.21z"
  //       />
  //       <path
  //         className="st0"
  //         d="M855.01,591.26l-15.26-36.84l-7.18,2.97l15.16,36.59c4.95,11.95,1.94,20.91-8.49,25.22 c-10.43,4.32-18.89,0.12-23.84-11.83l-15.16-36.59l-7.18,2.97l15.27,36.84c4.83,11.65,13.25,17.96,23.18,17.96 c3.41,0,6.99-0.74,10.66-2.27C856.57,620.33,861.49,606.9,855.01,591.26z"
  //       />
  //       <path
  //         className="st0"
  //         d="M900.05,606.03c9.98-2.38,17.69-7.44,22.28-14.64c4.57-7.17,5.78-16,3.51-25.55 c-2.27-9.55-7.34-16.88-14.65-21.22c-7.35-4.36-16.51-5.4-26.49-3.02l-20.75,4.94l15.35,64.44L900.05,606.03z M885.18,601.78 l-11.84-49.68l12.93-3.08c7.85-1.87,14.96-1.1,20.58,2.21c5.58,3.3,9.47,8.99,11.25,16.46c1.78,7.47,0.89,14.3-2.57,19.75 c-3.48,5.48-9.45,9.36-17.24,11.22L885.18,601.78z"
  //       />
  //       <rect
  //         x={941.58}
  //         y={530.89}
  //         transform="matrix(0.9926 -0.1218 0.1218 0.9926 -61.6443 119.3356)"
  //         className="st0"
  //         width={7.86}
  //         height={66.24}
  //       />
  //       <path
  //         className="st0"
  //         d="M999.32,595.01c0,0,0.01,0,0.01,0c9.89,0,18.41-3.32,24.63-9.6c6.12-6.17,9.49-14.95,9.48-24.73 c0-9.77-3.38-18.55-9.5-24.72c-6.22-6.27-14.74-9.58-24.62-9.58c-0.01,0-0.01,0-0.01,0c-9.72,0-18.45,3.41-24.6,9.6 c-6.14,6.18-9.52,14.96-9.52,24.73c0,9.76,3.39,18.54,9.53,24.72C980.87,591.61,989.61,595.01,999.32,595.01z M973.14,560.71 c0-7.75,2.51-14.38,7.26-19.16c4.74-4.77,11.28-7.3,18.91-7.3c0,0,0.01,0,0.01,0c7.67,0,14.24,2.52,18.99,7.29 c4.76,4.78,7.28,11.41,7.28,19.16c0.01,15.82-10.55,26.46-26.26,26.46c0,0-0.01,0-0.01,0 C983.67,587.15,973.14,576.53,973.14,560.71z"
  //       />
  //       <rect
  //         x={1078.21}
  //         y={562.71}
  //         transform="matrix(0.1888 -0.982 0.982 0.1888 310.5782 1530.6587)"
  //         className="st0"
  //         width={7.22}
  //         height={29.24}
  //       />
  //     </g> */}
  //     <g>
  //       <rect
  //         x={923.49}
  //         y={944.26}
  //         className="st0"
  //         width={64.53}
  //         height={126.8}
  //       />
  //       <polygon
  //         className="st0"
  //         points="1076.43,1128.27 988.01,1070.47 988.01,944.84 1076.43,887.04  "
  //       />
  //     </g>
  //   </g>
  // </svg>
  <svg
      xmlns="http://www.w3.org/2000/svg"
     
      fill="#FFFFFF"
      viewBox="0 0 2000 2000"
    >
      <path
        fill="#FFFFFF"
        d="M1080 1105.61c0 6.88-7.41 11.22-13.41 7.85l-102.002-57.38a8.99 8.99 0 01-4.588-7.84v-95.476a9.002 9.002 0 014.588-7.845l102.002-57.375c6-3.374 13.41.961 13.41 7.845v210.221z"
      ></path>
      <rect
        width="47.8"
        height="22.04"
        x="1103.4"
        y="929.645"
        fill="#FFFFFF"
        rx="5"
        transform="rotate(-31.036 1103.4 929.645)"
      ></rect>
      <rect
        width="47.8"
        height="22.04"
        x="1114.37"
        y="1039"
        fill="#FFFFFF"
        rx="5"
        transform="rotate(30 1114.37 1039)"
      ></rect>
      <rect
        width="47.8"
        height="22.04"
        x="1121"
        y="983.004"
        fill="#FFFFFF"
        rx="5"
      ></rect>
      <path
        fill="#FFFFFF"
        d="M908 955a9 9 0 019-9h49a9 9 0 019 9v91a9 9 0 01-9 9h-49a9 9 0 01-9-9v-91z"
      ></path>
    </svg>
);


export default SoundIcon;
