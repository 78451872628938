import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { AnimatePresence, motion } from "framer-motion";

const DetailCard = ({ item, setActive,tab }) => {

    return (
        <div
            className='w-full p-6 shadow-md text-white bg-primary space-y-12'>
            <div className='flex justify-between '>
                <div className='flex flex-col sm:flex-row sm:items-center sm:space-x-12 '>
                    <img src={item.path} className={`w-32 ${item.image_class?item.image_class:""}`} />
                    <div className={`${item.custom_classes?item.custom_classes:""}`}>
                        <h1 className='text-lg sm:text-3xl lg:text-3xl relative font-bold'>
                            {item.title}
                            <div className={`absolute -bottom-2 left-0 h-[2px] ${item.custom_width ? item.custom_width : "w-[120%]"} bg-white rounded-full`} />
                        </h1>
                        <p className='font-light mt-3 text-2xl'>{item.slogan}</p>
                    </div>
                </div>
                <div>
                    <AiOutlineCloseCircle className='text-5xl mt-4 cursor-pointer' onClick={() => setActive(null)} />
                </div>
            </div>
            <div className=''>
                {item.description}
            </div>

            <div className='h-[0.9px]  bg-white rounded-full' />

            <div className='text-sm'>
                <span className='font-bold'>{item.reference_length === 1 ? "REFERENZ":"REFERENZEN" }:</span>
                <div className={`${item.reference_length===1 ? "":"px-3"} break-words`}>
                    {item.references}
                </div>
            </div>

        </div>
    )
}

export default DetailCard