import React from 'react'

const MainRiskFact = () => {
    return (
        <><div className='pb-8 pt-4'>
            <div className='text-primary font-bold pb-4'>
            Was sind die Hauptrisikofaktoren für ASTHMA?<sup>1</sup>
            </div>
            <ul className='list-disc'>
                <li>
                <span className='font-medium'>Familiäre Veranlagung</span>: Das Risiko, an Asthma zu erkranken, ist höher, wenn andere Familienmitglieder ebenfalls Asthma haben – insbesondere ein naher Verwandter wie ein Elternteil oder Geschwister.
                </li>
                <li><span className='font-medium'>Allergische Erkrankungen</span>: Asthma ist häufiger bei Menschen, die auch andere allergische Erkrankungen wie Ekzeme und Rhinitis (Heuschnupfen) haben.</li>
                <li><span className='font-medium'>Urbanisierung</span>: Die Verstädterung ist mit einer höheren Asthma-Prävalenz verbunden, wahrscheinlich aufgrund verschiedener Lebensstilfaktoren.</li>
                <li><span className='font-medium'>Frühe Lebensereignisse</span>: Ereignisse im frühen Leben beeinflussen die Entwicklung der Lungen und können das Asthma-Risiko erhöhen. Dazu gehören ein niedriges Geburtsgewicht, Frühgeburtlichkeit, eine Exposition gegenüber Tabakrauch und anderen Luftverschmutzungsquellen sowie virale Atemwegsinfektionen.</li>
                <li><span className='font-medium'>Umweltallergene und Reizstoffe</span>: Die Exposition gegenüber einer Reihe von Umweltallergenen und Reizstoffen, einschließlich Innen- und Außenluftverschmutzung, Hausstaubmilben, Schimmelpilzen und beruflicher Exposition gegenüber Chemikalien, Dämpfen oder Staub, wird ebenfalls als Risikofaktor für Asthma angesehen.</li>
                <li><span className='font-medium'>Übergewicht und Adipositas</span>: Kinder und Erwachsene, die übergewichtig oder fettleibig sind, haben ein höheres Risiko, an Asthma zu erkranken.</li>
            </ul>
        </div><hr className=' bg-black text-black' style={{ height: '2px' }} /><div className='py-8 text-sm '>
                <p className='font-bold uppercase'>REFERENZEN:</p>
                <ul className=' list-decimal italic text-xs px-3 space-y-1 pt-1 break-all '>
                    <li>Global burden of 369 diseases and injuries in 204 countries and territories, 1990–2019: a systematic analysis for the Global Burden of Disease Study 2019. Lancet. 2020;396(10258):1204-22</li>


                </ul>
            </div></>
        
    )
}

export default MainRiskFact