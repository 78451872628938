import React from 'react'

const WhatIsCOPD = () => {
    return (
        <div className='pt-4'>
            <p>
                According to the latest estimates, <span className='font-medium'>COPD affects more than 300 million people worldwide</span> and the prevalence and burden of COPD
                are projected to increase over the coming decades due to a combination of continued exposure to <span className='font-medium'>COPD risk factors and aging of the
                    world’s population</span>.
                <sup>1,2</sup>
            </p>
            <p>
            It has also been observed that the <span className='font-medium'>prevalence among women is increasing,</span> due in part to increased tobacco use among women worldwide
                and exposure to biomass fuels.<sup>3</sup> According to the latest estimates of the WHO (World Health Organization), <span className='font-medium'>COPD is the third leading
                    cause of death worldwide</span>, causing 3.23 million deaths in 2019.<sup>1,2,4</sup>
            </p>
            <p>
                Extensive under-diagnosis and misdiagnosis leads to patients receiving no treatment or incorrect treatment. Appropriate and earlier
                diagnosis of COPD can have a very significant public-health impact.<sup>2</sup>
            </p>
            <div className='pt-5'>
                <span className='font-bold'>COPD AT A GLANCE</span>
                <div className='pt-3'>
                    <span className='text-primary font-bold'>COPD stands for Chronic Obstructive Pulmonary Disease</span>, which means<sup>5</sup>:

                    <ul className='list-disc py-2 px-9'>
                        <li><span className='text-primary font-bold'>C</span>hronic - it’s a long-term condition.</li>
                        <li><span className='text-primary font-bold'>O</span>bstructive - the airways have become narrower, slightly blocking, or obstructing, the flow of air during expiration.</li>
                        <li><span className='text-primary font-bold'>P</span>ulmonary - it affects the lungs.</li>
                        <li><span className='text-primary font-bold'>D</span>isease - it’s a medical condition.</li>
                    </ul>

                    <div className='py-8 space-y-4 text-light'>
                        <div className=''>
                            The term COPD is used to describe a <span className='font-medium'>group of lung conditions</span> that make it difficult to empty the air out of the lungs.
                            This happens when <span className='font-medium'>lung tissue has been damaged, making the airways narrower</span>. Mucus can also partially block airways, and the
                            lining of the airways can become swollen and inflamed. All these factors make it <span className='font-medium'>harder for air to move</span> in and out during breathing, and
                            the <span className='font-medium'>lungs are less able to take in oxygen</span> and get rid of carbon dioxide.<sup>5</sup>
                        </div>
                        <div>
                            The main COPD symptoms are <span className='font-medium'>dyspnea and/or cough with or without sputum production</span>. People living with <span className='font-medium'>COPD
                                may experience acute events characterized by increased respiratory symptoms called exacerbations</span> that influence their health
                            status and prognosis and <span className='font-medium'>require specific preventive and therapeutic measures</span>.<sup>2</sup>
                        </div>
                        <div>
                            Patients with <span className='font-medium'>COPD frequently harbor other comorbid diseases that also influence their clinical condition and prognosis</span> and
                                require specific treatment as well. These comorbid conditions can mimic and/or aggravate an acute exacerbation. The most frequent chronic
                            diseases associated with COPD include cardiovascular and respiratory disorders, osteoporosis, muscle wasting, metabolic and
                            neuropsychiatric disorders, chronic kidney disease, gastro-oesophageal reflux and others.
                        </div>
                        <div>
                            <span className='font-medium'>Comorbidities appear earlier in life in people with COPD</span> and often remain undiagnosed, misdiagnosed, unmanaged or
                            mismanaged. The frequent morbidities affecting patients with COPD do not occur at random, but are the result of syndemics, defined
                            as the occurrence of disease clusters with shared risk factors and biological interactions that exacerbate the prognosis and burden of
                            disease on individuals and society.<sup>6</sup>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WhatIsCOPD