import { configureStore } from "@reduxjs/toolkit";

//reducers below
import userReducer from "./slices/userSlice";
import languageReducer from "./slices/languageSlice";
import tabReducer from "./slices/tabSlice";
//reducers above

export default configureStore({
    reducer:{
        user: userReducer,
        language: languageReducer,
        tab: tabReducer,

    } 
})
