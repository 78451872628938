import React from 'react'

const MainRiskFact = () => {
    return (
        <div className='pb-8 pt-4'>
            <div className='text-primary font-bold pb-4'>
            Was sind die Hauptrisikofaktoren für COPD?
            </div>
            <div className='space-y-5 font-light'>
                <div>
                <span className='font-medium'>Die wichtigsten umgebungsbedingten Faktoren, die zu COPD führen, sind Tabakrauchen</span> und das Einatmen von giftigen Partikeln und Gasen aus der Haushalts- und Außenluftverschmutzung. Es gibt auch genetische Risikofaktoren, die einige Personen anfällig machen können, die Krankheit zu entwickeln; der relevanteste (wenn auch seltene) genetische Risikofaktor für COPD, der bisher identifiziert wurde, <span className='font-medium'>sind Mutationen im SERPINA1-Gen, die zu einem Alpha-1-Antitrypsin-Mangel führen</span>.<sup>1.2</sup>
                </div>
                <div>
                <span className='font-medium'>Ein weiterer wichtiger Aspekt ist COPD im Zusammenhang mit Ereignissen in der frühen Lebensphase, wie Frühgeburtlichkeit</span>. Frühgeburtlichkeit ist mit niedrigem Geburtsgewicht, Ernährungsproblemen, Anfälligkeit für Atemwegsinfektionen und schlechter Lungenfunktion im frühen Leben verbunden. Zusammen mit Belastungen wie der Exposition gegenüber Tabakrauch in der zweiten Schwangerschaftshälfte kann Frühgeburtlichkeit die Entwicklung der Alveolen(Lungenbläschen), Gefäße und Atemwege beeinträchtigen, was zu einer reduzierten Lungenreifung nach der Geburt führt, <span className='font-medium'>insbesondere bei Säuglingen, die vor der 28. Schwangerschaftswoche geboren wurden. Diese krankhaften Veränderungen erhöhen die Anfälligkeit für COPD im späteren Leben</span>.<sup>2</sup>

                </div>
            </div>
        </div>
    )
}

export default MainRiskFact