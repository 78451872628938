import React, { useEffect } from "react";

import TranslationContainer from "./TranslationContainer";
import { Provider } from "react-redux";
import store from "./redux/store";

const App = () => {

  return (
    <>
        <Provider store={store}>
          <TranslationContainer/>
        </Provider>
    </>
  );
}

export default App;
