import React, { useEffect, useRef, useState } from 'react'
import SoundIcon from './SoundIcon'
import { AnimatePresence,motion } from 'framer-motion';
import './style.css'
import ModalVideo from 'react-modal-video';
import { AiOutlineSound } from 'react-icons/ai';

const VideoContainer = ({url, secondary_url}) => {
    const  videoRef = useRef()
    const [open, setOpen] = useState(false);
    const [showSoundIcon, setShowSoundIcon] = useState(false);
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);

    
    useEffect(() => {
        // Variable to store the SVG content
        if(videoRef.current){
            videoRef.current.muted = true;
            videoRef.current.play();

        }
        const handleMouseMove = (e) => {
            // Get the bounding rectangle of the video container
                
                // var textbox = document.getElementById(video_id?video_id:'video-container').getBoundingClientRect();
                var textbox = videoRef.current?.getBoundingClientRect();

                const mouseX = e.clientX;
                const mouseY = e.clientY;
    
            // Check if the mouse coordinates are within the bounding rectangle
            if(textbox){
                if (
                    mouseX >= textbox.left &&
                    mouseX <= textbox.right &&
                    mouseY >= textbox.top &&
                    mouseY <= textbox.bottom
                ) {
                    setShowSoundIcon(true)
                } else {
                    setShowSoundIcon(false)
                }
            }
            setMouseX(mouseX);
            setMouseY(mouseY);
        };
        // const handleScroll = (e) => {

        //     var textbox  = videoRef.current?.getBoundingClientRect();
        //     // const sound_icon = document.getElementById("sound-icon")?.getBoundingClientRect()
        //     console.log("mouseX",mouseX)
        //     console.log("mouseY",mouseY)
        //     if (
        //         mouseX >= textbox.left &&
        //         mouseX <= textbox.right &&
        //         mouseY >= textbox.top &&
        //         mouseY <= textbox.bottom
        //     ) {
        //         setShowSoundIcon(true);
        //     } else {
        //         setShowSoundIcon(false);
        //     }
        // };
    
        document.addEventListener('mousemove', handleMouseMove);
        // document.addEventListener('scroll', handleScroll);

    
        return () => {
            // Clean up event listener on unmount
            document.removeEventListener('mousemove', handleMouseMove);
            // document.removeEventListener('scroll', handleScroll);

        };
    }, []);



   
    return (
        <div key={url}>
            <AnimatePresence>
                {
                    (!open) && showSoundIcon &&
                    <motion.div id='sound-icon'
                        initial={{
                            opacity: 0,
                            scale: 0.7,
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                            x: "-50%",
                            y: "-50%",
                            transition: {
                                type: "tween",
                                duration: 0.3
                            }
                        }}
                        exit={{
                            opacity: 0,
                        }}

                        className='fixed top-0 translate-y-[-50%] translate-x-[-50%] w-52'
                        style={{
                            top: mouseY,
                            left: mouseX
                        }}>
                        <Container setShowSoundIcon={setShowSoundIcon} mouseY={mouseY} mouseX={mouseY} videoRef={videoRef}/>
                    </motion.div>
                }
            </AnimatePresence>
            
            {/* <div className=' relative'> */}
                <video ref={videoRef} playsInline autoPlay loop muted width="100%" id={"video-container"} onClick={()=>setOpen(true)}
                    className='object-cover w-full h-full cursor-pointer'>
                    <source src={url} type="video/mp4" />
                </video>
                <div className='absolute top-2 md:top-4 right-2 md:right-4 px-8 py-1 md:py-3 rounded-xl bg-white  bg-opacity-25 ' onClick={()=>setOpen(true)}>
                    <AiOutlineSound className='text-xl text-white'/>
                </div>
            {/* </div> */}

            <ModalVideo
				channel="custom"
				isOpen={open}
                url={url}
				onClose={() => setOpen(false)} 
			/>


        </div>
    )
}

export default VideoContainer




const Container = ({mouseX,mouseY,videoRef,setShowSoundIcon}) =>{

    // if cursor is changed with sound icon and inside the video container and mouse doesn't move only scroll on document is moved
    // then determine either my mouse Cordinates are still inside the video container or not by adding scroll event. 
    // i can't detect my mouse cordinates are still inside the video container or not So, I'm useing PreviousMouseX/y state just 
    // because to save last mouse X/Y cordinates.

    const [previouseMouseY,setPreviousMouseY]  = useState(0)  
    const [previouseMouseX,setPreviousMouseX]  = useState(0)
    
    useEffect(()=>{
        setPreviousMouseY(mouseY)
        setPreviousMouseX(mouseX)
        document.addEventListener('scroll', handleScroll);
        
        
        return () => {
            // Clean up event listener on unmount
            document.removeEventListener('scroll', handleScroll);
            
        };

    },[mouseY,mouseX]) 
    
    const handleScroll = () => {
        var textbox  = videoRef.current?.getBoundingClientRect();
        if(textbox)
            {
                if ( !(previouseMouseX >= textbox.left && previouseMouseX <= textbox.right && previouseMouseY >= textbox.top && previouseMouseY <= textbox.bottom) ) {
                    console.log("cursor outside the video container")
                    setShowSoundIcon(false);
                } 
            }
    };

     // if cursor is changed with sound icon and inside the video container and mouse doesn't move only scroll on document is moved
    // then determine either my mouse Cordinates are still inside the video container or not by adding scroll event. 
    // i can't detect my mouse cordinates are still inside the video container or not So, I'm useing PreviousMouseX/y state just 
    // because to save last mouse X/Y cordinates.
    



    return(
        <>
        <SoundIcon />
        {/* <AiOutlineSound className='text-xl translate-x-1/2'/> */}

        </>
    )
}