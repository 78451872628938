import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tab: "asthma"
}

const tabSlice = createSlice({
    initialState,
    name: "tab",
    reducers: {
        set_tab: (state, action) => {
            state.tab = action.payload;
        },
    }
})

export const {
    set_tab,
} = tabSlice.actions

export default tabSlice.reducer