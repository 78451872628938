import React from 'react'

const MainRiskFact = () => {
    return (
        <div className='pb-8 pt-4'>
            <div className='text-primary font-bold pb-4'>
                What are the main risk factors associated with COPD?
            </div>
            <div className='space-y-5 font-light'>
                <div>
                    <span className='font-medium'>The main environmental exposures leading to COPD are tobacco smoking</span> and the inhalation of toxic particles and gases from
                    household and outdoor air pollution. There are also genetic risk factors that may predispose some individuals to developing the disease;
                    the most relevant (albeit rare) genetic risk factor for COPD identified to date <span className='font-medium'>is mutations in the SERPINA1 gene that lead to α-1
                        antitrypsin deficiency</span>.<sup>1,2</sup>
                </div>
                <div>
                    <span className='font-medium'>Another important aspect is COPD related to early-life events, like prematurity</span>. Prematurity is associated with low birth weight,
                    nutritional problems, susceptibility to respiratory infections, and poor lung function early in life. Together with insults such as
                    exposure to tobacco smoke in the second half of pregnancy, prematurity can cause impairment in alveolar, vascular,
                    and airway development, leading to reduced lung maturation postnatally, <span className='font-medium'>particularly in infants born before 28 weeks’ gestation.
                        These pathological changes increase susceptibility to COPD later in life</span>.<sup>2</sup>

                </div>
            </div>
        </div>
    )
}

export default MainRiskFact