import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from 'react-redux';
import { handleScroll } from '../../../utils';
import { ENGLISH_LANGUAGE, FRENCH_LANGUAGE, ITALIAN_LANGUAGE } from '../../../constants';

const First = ({ sliderRef, active, setCurrentVideo,refOffset }) => {
    const lang = useSelector(state => state.language.lang)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            ref.current.load();
            ref.current.muted = true;
            ref.current.play();
        }
    }, [lang])

    useEffect(() => {
        sliderRef.current.swiper.slideTo(0);

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768)
        }

        // Attach the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        if (ref.current) {
            ref.current.load();
            ref.current.muted = true;
            ref.current.play();
        }
    }, isMobile)

    useEffect(() => {
        if (active) {
            setCurrentVideo(0)
        }
    }, [active])

    return (
        <div className='w-screen h-screen'>

            <AnimatePresence>
                {
                    active &&
                    <motion.div
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{
                            opacity: 0
                        }}
                        className="flex items-center justify-center absolute top-0 bottom-0 right-0 left-0 z-1"
                    >
                        <video ref={ref} loop playsInline autoPlay muted width="100%" className='object-cover w-full h-full'>
                            {isMobile ? (
                                <source src={`/videos/carousel/${
                                    lang.value===ENGLISH_LANGUAGE.value?
                                    "223_chiesi 23k_landing mobile ENG.mp4"
                                    :lang.value===FRENCH_LANGUAGE.value?
                                    "french/223_chiesi 23k_landing mobile FRA.mp4"
                                    :lang.value===ITALIAN_LANGUAGE.value?
                                    "Italian/223_chiesi 23k_landing mobile ITA.mp4"
                                    :
                                    ""
                                }`} type="video/mp4" />
                            ) : (
                                <source src={`/videos/carousel/${
                                    lang.value===ENGLISH_LANGUAGE.value?
                                    "223_chiesi 23k_landing desktop ENG.mp4"
                                    :
                                    lang.value===FRENCH_LANGUAGE.value?
                                    "french/223_chiesi 23k_landing desktop FRA.mp4"
                                    : lang.value===ITALIAN_LANGUAGE.value?
                                    "Italian/223_chiesi 23k_landing desktop ITA.mp4"
                                    :
                                    ""
                                    
                                }`} type="video/mp4" />
                            )}
                        </video>
                    </motion.div>
                }
            </AnimatePresence>
        </div>

    )
}

export default First