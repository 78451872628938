import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/header'
import English from './English'
import French from './French'
import Italian from './Italian'
import Dutch from './Dutch'
import { useDispatch, useSelector } from 'react-redux';
import { DUTCH_LANGUAGE, FRENCH_LANGUAGE, ITALIAN_LANGUAGE } from '../../constants';
import { set_lang } from '../../redux/slices/languageSlice';
import { setLang } from '../../utils';

const Main = ({language}) => {
  const lang = useSelector(state => state.language.lang);
  const dispatch = useDispatch();

  const [refOffset,setRefOffset]=useState()
  const ref=useRef(null)


  useEffect(()=>{
    dispatch(set_lang(language))
    setLang(language)
  },[])


  return (
    <div className='flex h-full flex-col'>
      <Header refOffset={refOffset}/>
      <div ref={ref}>
        {
          lang.value===FRENCH_LANGUAGE.value?
          <French/>
          :lang.value===ITALIAN_LANGUAGE.value?
          <Italian/>
          :lang.value===DUTCH_LANGUAGE.value?
          <Dutch/>
          :
          <English/>
        }
      </div>
      
    </div>
  )
}

export default Main