import { createSlice } from "@reduxjs/toolkit";
import { getLang, getLangSelected } from "../../utils";

const initialState = {
    lang: getLang(),
}

const languageSlice = createSlice({
    initialState,
    name: "language",
    reducers: {
        set_lang: (state, action) => {
            state.lang = action.payload;
        },
    }
})

export const {
    set_lang,
} = languageSlice.actions

export default languageSlice.reducer