import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ENGLISH_LANGUAGE } from '../../../constants';
import Container from './Container';

const English = () => {
  const lang = useSelector(state => state.language.lang);
  return (
    <div className='relative pt-5 px-4 sm:px-8 lg:24 xl:px-32'>
      <Container />
    </div>
  )
}

export default English