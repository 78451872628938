import React from 'react'

const AreYouCareGiver = () => {
    return (
        <div className='mt-8 pb-8 px-5 sm:px-12 leading-tight font-light bg-primary text-[19px]  text-white '>
            <div className='flex  items-center space-x-5'>
                <img src='/images/peoples.png' className='w-32' />
                <div>
                    <h1 className='text-base sm:text-lg lg:text-xl font-bold uppercase'>ÊTES-VOUS UN PROCHE AIDANT ?</h1>
                    <p className='font-light'>Des choses simples à faire</p>
                </div>

            </div>
            <div className='-mt-4'>
                <hr className=' bg-white  text-white' style={{ height: '1px' }} />
            </div>


            <div className=' -mt-1 pb-3 text-justify '>
                <div className='mt-8'>
                <span className='font-medium'>S'occuper d'une personne atteinte de BPCO n'est pas toujours facile, surtout s'il s'agit d'un être cher</span>. Votre vie changera, peut-être aussi vos habitudes et votre routine, et cela vous rendra peut-être nerveux, voire déprimé, ou anxieux.
                </div>
                <div className='mt-4'>
                La solution pour mieux prendre soin d'une personne souffrant d'une pathologie pourrait être de <span className='font-medium'> prendre soin de vous-mêmes, dans la mesure du possible. Prenez du temps pour vous, rechargez vos batteries intérieures</span>. 
                Si vous vous sentez stressé et surmené, <span className='font-medium'>demandez de l'aide</span>, <span className='font-medium'>faites appel à un parent</span>, à un ami et <span className='font-medium'>trouvez le temps et l'énergie nécessaires</span> pour retrouver la force et la volonté <span className='font-medium'>de soutenir les personnes que vous aidez</span>. 
                </div>
                <div className='mt-4 font-bold'>
                    Votre bien-être est fondamental pour le leur.
                </div>
            </div>
        </div>
    )
}

export default AreYouCareGiver