import React from 'react'
import Card from '../ITCard'
import { AnimatePresence, motion } from "framer-motion";
import DetailCard from '../ITCard/DetailCard';
import { ArrayCard, DetailArrayCard } from '../ITCard/ArrayCard'

const HowToLive = ({ active, setActive }) => {
    const handleClick = (item, index) => {
        let x = {
            ...item,
            activeIndex: index
        }
        setActive(x)
    }


    return (

        <div className='space-y-5 font-light pt-4'>
            <div className=''>
            <span className='font-medium'>Vivere con la BPCO</span> non significa semplicemente sopravvivere; richiede che <span className='font-medium'>vengano attuati cambiamenti positivi che possono fare davvero la differenza</span>. Adottando abitudini sane e dando priorità al tuo benessere, <span className='font-medium'>puoi gestire in modo efficace l'impatto della BPCO sulla tua vita quotidiana</span>. Avere informazioni accurate e nutrire sia il corpo sia la mente, attraverso semplici azioni quotidiane, è la chiave per il benessere. 
            Per permetterti di vivere al meglio la tua vita quotidiana e minimizzare i problemi della BPCO, <span className='font-medium'>ecco alcuni consigli pratici da seguire, respiro dopo respiro</span>.

            </div>
            {
                active === null ?
                    <div className='font-medium'>
                        Per ulteriori informazioni e consigli sulla BPCO, contatta il tuo medico.
                    </div>
                    :
                    ""
            }
            <AnimatePresence mode='sync' >
                {
                    active &&
                    <motion.div
                        key={"detail-poup"}
                        initial={{ 
                            height: "0",
                            opacity: 0
                        }}
                        animate={{ 
                            height: "auto",
                            opacity: 1
                        }}
                        exit={{ 
                            height: "0",
                            opacity: 0
                        }}
                        className='overflow-hidden'
                        transition={{
                            type:"tween",
                            duration: 0.3
                        }}
                    >
                        <DetailCard
                            item={DetailArrayCard[active.activeIndex]}
                            setActive={setActive}
                        />
                    </motion.div>

                }
                 {
                active === null &&
                    <motion.div
                        key={"grid"}
                        initial={{ 
                            height: "0",
                            opacity: 0
                        }}
                        animate={{ 
                            height: "auto",
                            opacity: 1
                        }}
                        exit={{ 
                            height: "0",
                            opacity: 0
                        }}
                        transition={{
                            type:"tween",
                            duration: 0.3
                        }}
                        className='origin-bottom overflow-hidden '
                        >
                            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5  pt-8 pb-12'>

                                <div className='h-[210px]  p-6 cursor-pointer text-primary '>
                                    <h1 className='font-medium  text-3xl w-full'>Alcuni consigli </h1>
                                    <h1 className='font-medium mb-3 text-3xl w-full'>pratici</h1>
                                    <div>
                                        <img src='/images/row_guide.png' className='w-24' />
                                    </div>
                                </div>
                                {

                                    ArrayCard.map((item, index) => (
                                        <div onClick={() => handleClick(item, index)}>
                                            <Card
                                                key={`item${index}`}
                                                index={index}
                                                item={item}
                                                active={active}
                                                setActive={setActive}
                                            />
                                        </div>

                                    ))
                                }
                            </div>

                    </motion.div>
            }
            </AnimatePresence>

           
        </div>
    )
}

export default HowToLive