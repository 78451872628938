import React from 'react'

const AreYouCareGiver = (mainTab) => {
    const tab = mainTab.mainTab
    return (
        <div className='mt-8 pb-8 px-5 sm:px-12 leading-tight font-light bg-primary text-[19px]  text-white '>
            <div className='flex  items-center space-x-5'>
                <img src='/images/peoples.png' className='w-32' />
                <div>
                    <h1 className='text-base sm:text-lg lg:text-xl font-bold text-md'> ARE YOU A CAREGIVER?</h1>
                    <p className='font-light text-[14px] sm:text-[19px]'>Simple things you can do</p>
                </div>

            </div>
            <div className='-mt-4'>
                <hr className=' bg-white  text-white' style={{ height: '1px' }} />
            </div>


            {tab == "COPD" ? <div className=' -mt-1 pb-3 text-justify '>
                <div className='mt-8'>
                    <span className='font-semibold'>It’s not a simple feat, caring for a person with COPD, especially if it’s someone you love</span>.
                    Your life will change, maybe also your habits and your routine, and this may make you feel nervous, even depressed or anxious.
                </div>
                <div className='mt-4'>
                    The solution to better take care of someone suffering from a condition could be <span className='font-bold'>
                        taking care of yourself, when possible. Take time for yourself, recharge your inner batteries</span>. If you are feeling stressed and overworked, <span className='font-bold'>ask for help, involve a relative </span>
                    or a friend and find the time and energy to <span className='font-bold'>regain the strength and</span>  <span className='font-bold'>will to support the persons you are assisting</span>.
                </div>
                <div className='mt-4 font-bold'>
                    Your well-being is fundamental for their well-being, so taking care of yourself is a way of taking better care of them.
                </div>
            </div>
                :
                <div className=' -mt-1 pb-3 text-justify '>
                    <div className='mt-8'>
                        Here are some useful suggestions to keep in mind for  <span className='font-medium'>helping your loved ones to overcome the limits imposed by asthma</span>: assist them in maintaining weight
                        control, avoiding smoke, and engaging in physical activity.
                    </div>
                    <div className='mt-4'>
                        One of the most important suggestions, though, is about you. <span className='font-medium'>Take care of yourself, whenever it’s possible. Your well-being is fundamental for their well-being
                        as well</span>. So then, take your time, recharge your inner batteries, ask for help when necessary and try to find the time and energy to regain strength, <span className='font-medium'>so that
                        both of you can achieve limitless goals.</span>
                    </div>
                </div>
            }
        </div>
    )
}

export default AreYouCareGiver