import React, { useState } from "react";

// notifications alerts
// import 'react-notifications-component/dist/theme.css'
// import ReactNotification from 'react-notifications-component'
import 'animate.css/animate.min.css';
// notifications alerts

//multilingual imports
import { IntlProvider } from "react-intl";
import en from "./languages/en.json";
import it from "./languages/it.json";
import es from "./languages/es.json";
import fr from "./languages/fr.json";
import de from "./languages/de.json";
//multilingual imports

//router
import { BrowserRouter } from "react-router-dom";
import BaseRoutes from "./routes";
//router

//global popup
import BasePopup, { BasePopupContext } from './components/BasePopup';
import { DUTCH_LANGUAGE, ENGLISH_LANGUAGE, FRENCH_LANGUAGE, ITALIAN_LANGUAGE, SPANISH_LANGUAGE } from "./constants";
import { useSelector } from "react-redux";
//global popup

const TranslationContainer = () => {
  const lang = useSelector(state => state.language.lang)
  // state for popup
  const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
  }
  const [popupState, setPopupState] = useState(initialPopupState);

  const openPopup = (params) => setPopupState(old => ({ ...old, ...params, }));

  const global_popup_context_data = {
    popupState,
    setPopup: openPopup
  }
  // state for popup

  return (
    <>
      <IntlProvider messages={
        lang.value === ENGLISH_LANGUAGE.value ?
          en
          : lang.value === ITALIAN_LANGUAGE.value ?
            it
            : lang.value === FRENCH_LANGUAGE.value ?
              fr
              : lang.value === SPANISH_LANGUAGE.value ?
                es
                : lang.value === DUTCH_LANGUAGE.value ?
                  de
                  : en
      } locale="en" defaultLocale="en">

        <BasePopupContext.Provider value={global_popup_context_data}>

          <BrowserRouter>
            {/* <ReactNotification /> */}
            <BaseRoutes />
          </BrowserRouter>
        </BasePopupContext.Provider>
      </IntlProvider>
    </>
  );
}

export default TranslationContainer;
