import React, { useEffect } from 'react'
import LangSelect from "./LangSelect";
import English from './English';
import { setLang } from '../../utils';
import { set_lang } from '../../redux/slices/languageSlice';
import { useDispatch } from 'react-redux';

const CookiePolicy = ({language}) => {
  const dispatch = useDispatch()

  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
      dispatch(set_lang(language))
      setLang(language)
  },[])


  return (
    <div className='flex h-full flex-col'>
      <header className={`flex-shrink-0`}>
            <div>
            <LangSelect/>
            <a href="/copd">
                <img src="/images/logos/logo-top-mobile-1.png" className='block sm:hidden w-full relative z-10 -mt-1' />
                <img src="/images/logos/logo-top-desktop.png" className='hidden sm:block w-10/12 relative z-10 -mt-1' />
            </a>
            </div>
        </header>

        <div className='flex-grow'>
            <English/>
        </div>

        <footer className='flex-shrink-0'>
                <img src="/images/logos/logo-bottom-mobile-1.png" className='-mb-1 block sm:hidden w-full relative z-10 -mt-1' />
                <img src="/images/logos/logo-bottom-desktop.png" className='ml-auto hidden sm:block w-10/12 relative z-10 -mt-1' />
        </footer>
      
    </div>
  )
}

export default CookiePolicy