import React, { useEffect, useState } from 'react'

import WhatIsCOPD from './WhatIsCOPD';
import MainRiskFact from './MainRiskFact';
import HowToLive from './HowToLive';
import References from './References';
import About from './About';
import AreYouCareGiver from './AreYouCareGiver';

const English = () => {
    const [tab, setTab] = useState(0)
    const [active, setActive] = useState(null)


    useEffect(()=>{
        setActive(null)
    },[tab])



    return (
        <>
            <div className='px-5 sm:px-12 mt-5 text-justify '>
                <h2 className='text-xl sm:text-3xl lg:text-4xl font-bold text-primary text-center mb-3 sm:mb-8'>
                    NOTRE DIALOGUE OUVERT AVEC LES PATIENTS
                </h2>

                <div className='mb-5'>
                    <p className="text-base sm:text-lg lg:text-xl text-ju  leading-tight">
                    C’est dans notre <strong className=''>dialogue permanent avec les personnes atteintes de maladies respiratoires</strong> que nous puisons l'inspiration et les connaissances qui nous permettent de nous améliorer et d’innover en permanence.
                    </p>
                    <p className="text-base sm:text-lg lg:text-xl leading-tight">
                    Le groupe Chiesi est pleinement engagé dans les soins et l'amélioration de la qualité de vie des personnes souffrant de maladies respiratoires telles que l'asthme et la BPCO (bronchopneumopathie chronique obstructive).    
                    </p>
                </div>

                <div className='mb-5 sm:mb-8 space-y-6'>
                    <p className="text-base sm:text-lg lg:text-xl leading-tight">
                        <strong>La capacité de respirer librement est un don qui ne devrait jamais être considéré comme acquis</strong>.
                    </p>
                    <p className="text-base sm:text-lg lg:text-xl leading-tight">
                        Faites défiler vers le bas pour en savoir plus sur la BPCO et adopter les mesures nécessaires pour prendre soin de votre santé respiratoire.
                    </p>
                </div>
            </div>
            <div>

                <SelectionTab tab={tab} setTab={setTab} setActive={setActive}/>


                <div className='pt-4 xl:pt-0  px-5 sm:px-12'>
                    <div className='px-5 text-justify sm:px-12 py-6 space-y-2 text-base sm:text-lg lg:text-xl leading-tight  font-light bg-gray-200 '>
                        {
                            tab === 0 ?
                                <WhatIsCOPD />
                                : tab === 1 ?
                                    <MainRiskFact />
                                    : tab == 2 ?
                                        <HowToLive active={active} setActive={setActive} />
                                        : ""
                        }

                        {
                            tab !=2&&
                            <>
                            <hr className=' bg-black text-black' style={{ height: '2px' }} />
                            <References />
                            </>
                        }

                    </div>
                   <AreYouCareGiver/>
                </div>
            </div>

            <About />

        </>
    )
}

export default English





export const SelectionTab = ({tab,setTab,setActive}) => {


 
    return (
        <>
        <div className='px-5 sm:px-12  grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 '>
                    <div className=''>
                        <div className={`flex-shrink-0 text-center text-[15px] lg:text-[19px] w-auto cursor-pointer ${tab === 0 ? "bg-gray-200  " : "border-2"} border-2   p-4 uppercase text-primary font-bold`} onClick={() => setTab(0)}>
                            QU'EST-CE QUE LA BPCO ?
                        </div>
                    </div>
                    <div className=''>
                        <div className={`flex-shrink-0 text-center text-[15px] lg:text-[19px] w-auto cursor-pointer ${tab === 1 ? "bg-gray-200" : "border-2"}  border-2 p-4 uppercase text-primary font-bold `} onClick={() => setTab(1)}>
                        LES PRINCIPAUX FACTEURS DE RISQUE 
                        </div>
                    </div>
                    <div className=''>
                        <div className={`flex-shrink-0 text-center text-[15px] lg:text-[19px] w-auto cursor-pointer ${tab === 2 ? "bg-gray-200" : "border-2"}  border-2 p-4 uppercase text-primary font-bold`} onClick={() => setTab(2)}>
                        VIVRE AVEC LA BPCO 
                        </div>

                    </div>
                </div>
                <div className={`  hidden xl:block    px-5 sm:px-12  xl:grid xl:grid-cols-3 gap-3`}>
                    <div className={`${tab === 0 ? " h-4 bg-gray-200 " : ""}`} ></div>
                    <div className={`${tab === 1 ? "h-4 bg-gray-200" : ""}`} ></div>
                    <div className={`${tab === 2 ? "h-4 bg-gray-200" : ""}`} ></div>
                </div>

        </>
    )
}