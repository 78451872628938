import React from 'react'
import Card from '../Card'
import { AnimatePresence, motion } from "framer-motion";
import DetailCard from '../Card/DetailCard';
import { ArrayCard, DetailArrayCard } from '../Card/ArrayCard'

const HowToLive = ({ active, setActive }) => {
    const handleClick = (item, index) => {
        let x = {
            ...item,
            activeIndex: index
        }
        setActive(x)
    }


    return (

        <div className='space-y-5 font-light pt-4'>
            <div className=''>
            <span className='font-medium'>
            Vivre avec la BPCO </span>ne signifie pas simplement subir la maladie ; il s'agit <span className='font-medium'>également d'adopter des habitudes positives qui peuvent faire une réelle différence.</span> En adoptant des habitudes saines et en donnant la priorité à 
            votre bien-être, <span className='font-medium'>vous pouvez gérer efficacement l'impact de la BPCO sur votre vie quotidienne.</span> Il est essentiel de recueillir des informations précises et de prendre soin de votre corps et de votre esprit par des actions simples et intentionnelles. 
            Pour vous aider à tirer le meilleur parti possible de votre vie quotidienne et <span className='font-medium'>à minimiser les difficultés liées à la BPCO, voici quelques conseils pratiques à prendre en compte, une respiration après l’autre</span>.
            </div>
            {
                active === null ?
                    <div className='font-medium'>
                        POUR DE PLUS AMPLES INFORMATIONS ET CONSEILS SUR LA BPCO, VEUILLEZ CONSULTER VOTRE MÉDECIN.
                    </div>
                    :
                    ""
            }
            <AnimatePresence mode='sync' >
                {
                    active &&
                    <motion.div
                        key={"detail-poup"}
                        initial={{ 
                            height: "0",
                            opacity: 0
                        }}
                        animate={{ 
                            height: "auto",
                            opacity: 1
                        }}
                        exit={{ 
                            height: "0",
                            opacity: 0
                        }}
                        className='overflow-hidden'
                        transition={{
                            type:"tween",
                            duration: 0.3
                        }}
                    >
                        <DetailCard
                            item={DetailArrayCard[active.activeIndex]}
                            setActive={setActive}
                        />
                    </motion.div>

                }
                 {
                active === null &&
                    <motion.div
                        key={"grid"}
                        initial={{ 
                            height: "0",
                            opacity: 0
                        }}
                        animate={{ 
                            height: "auto",
                            opacity: 1
                        }}
                        exit={{ 
                            height: "0",
                            opacity: 0
                        }}
                        transition={{
                            type:"tween",
                            duration: 0.3
                        }}
                        className='origin-bottom overflow-hidden '
                        >
                            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5  pt-8 pb-12'>

                                <div className='mb-8 sm:mb-0 h-[210px] p-6 cursor-pointer text-primary '>
                                    <h1 className='font-medium mb-3 text-3xl'>UN GUIDE POUR UNE VIE ÉPANOUIE</h1>
                                    <div>
                                        <img src='/images/row_guide.png' className='w-24' />
                                    </div>
                                </div>
                                {

                                    ArrayCard.map((item, index) => (
                                        <div onClick={() => handleClick(item, index)}>
                                            <Card
                                                key={`item${index}`}
                                                index={index}
                                                item={item}
                                                active={active}
                                                setActive={setActive}
                                            />
                                        </div>

                                    ))
                                }
                            </div>

                    </motion.div>
            }
            </AnimatePresence>

           
        </div>
    )
}

export default HowToLive