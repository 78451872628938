import React from 'react'
import Card from '../../Card'
import { AnimatePresence, motion } from "framer-motion";
import DetailCard from '../../Card/DetailCard';
import { AsthamaArrayCard, AsthamaDetailArrayCard } from '../../Card/ArrayCard'

const HowToLive = ({ active, setActive }) => {
    const handleClick = (item, index) => {
        let x = {
            ...item,
            activeIndex: index
        }
        setActive(x)
    }


    return (

        <div className='space-y-5 font-light pt-4'>
            <div className=''>
                Asthma can impose limits in daily life. <span className='font-medium'>Adopting healthy habits and prioritizing your well-being, however, can effectively help you to overcome these limits</span>.
                Equipping yourself with <span className='font-medium'>accurate information and nurturing both your body and mind</span> through simple, intentional actions is the key. Here are some practical tips to consider, to <span className='font-medium'>enjoy a life of limitless possibilities</span>.   
            </div>
            {
                active === null ?
                    <div className=''>
                        FOR FURTHER INFORMATION AND ADVICE ON ASTHMA, WE ENCOURAGE YOU TO CONTACT YOUR HEALTHCARE PROFESSIONAL.
                    </div>
                    :
                    ""
            }
            <AnimatePresence mode='sync' >
                {
                    active &&
                    <motion.div
                        key={"detail-poup"}
                        initial={{
                            height: "0",
                            opacity: 0
                        }}
                        animate={{
                            height: "auto",
                            opacity: 1
                        }}
                        exit={{
                            height: "0",
                            opacity: 0
                        }}
                        className='overflow-hidden'
                        transition={{
                            type: "tween",
                            duration: 0.3
                        }}
                    >
                        <DetailCard
                            item={AsthamaDetailArrayCard[active.activeIndex]}
                            setActive={setActive}
                        />
                    </motion.div>

                }
                {
                    active === null &&
                    <motion.div
                        key={"grid"}
                        initial={{
                            height: "0",
                            opacity: 0
                        }}
                        animate={{
                            height: "auto",
                            opacity: 1
                        }}
                        exit={{
                            height: "0",
                            opacity: 0
                        }}
                        transition={{
                            type: "tween",
                            duration: 0.3
                        }}
                        className='origin-bottom overflow-hidden '
                    >
                        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5  pt-8 pb-12'>

                            <div className='h-[210px] w-[210px] p-6 cursor-pointer text-primary '>
                                <h1 className='font-medium mb-3 text-3xl'>A guide to a fulfilling life</h1>
                                <div>
                                    <img src='/images/row_guide.png' className='w-24' />
                                </div>
                            </div>
                            {

                                AsthamaArrayCard.map((item, index) => (
                                    <div onClick={() => handleClick(item, index)}>
                                        <Card
                                            key={`item${index}`}
                                            index={index}
                                            item={item}
                                            active={active}
                                            setActive={setActive}
                                        />
                                    </div>

                                ))
                            }
                        </div>

                    </motion.div>
                }
            </AnimatePresence>


        </div>
    )
}

export default HowToLive