import React from 'react'

const WhatIsAsthama = () => {
    return (
        <div className='pt-4'>
            <p>
                Asthma ist eine chronische Lungenerkrankung, von der weltweit <span className='font-medium'>262 Millionen Menschen</span> aller Altersgruppen betroffen sind.
                <sup>1</sup></p>
            <p >Sie wird durch Entzündungen und Verkrampfungen in den Atemwegen verursacht, was das <span className='font-medium'>Atmen erschwert</span>.</p>
            <p className='pt-5'>
                Insbesondere in Ländern mit niedrigem und mittlerem Einkommen wird Asthma oft nicht diagnostiziert und nicht ausreichend behandelt, was zu einer schwerwiegenderen Verschlechterung der Erkrankung bei den Betroffenen führen kann.<sup>2</sup> Darüber
                hinaus ist Asthma eine "unsichtbare" Krankheit, da sie von außen nicht erkennbar ist, obwohl sie die Bewegungen, Sinne oder Aktivitäten einer Person einschränken oder herausfordern kann.<sup>3</sup>
            </p>
            <div className='pt-5'>

                <p>Menschen mit unzureichend behandeltem Asthma können unter Schlafstörungen,
                    Tagesmüdigkeit und Konzentrationsschwierigkeiten leiden. Asthma-Patient:innen und ihre Familien können aufgrund von Krankenständen Schule und Arbeit verpassen, was finanzielle Auswirkungen auf die
                    Familie und die Gemeinschaft haben kann. Bei schweren Symptomen müssen Asthmatiker:innen
                    möglicherweise eine Notfallversorgung in Anspruch nehmen und im Krankenhaus behandelt und überwacht werden. In den schwersten Fällen kann Asthma zum Tod führen<sup>2</sup>.
                </p>

                <div className=' py-2'>Daher ist es sehr wichtig, die häufigen Symptome nicht zu unterschätzen. Zu denen können gehören<sup>2</sup>:
                    <ul className='list-disc px-5 '>
                        <li className='font-medium'>Ein anhaltender Husten, besonders in der Nacht</li>
                        <li className='font-medium'>Keuchen beim Ausatmen und manchmal auch beim Einatmen</li>
                        <li className='font-medium'>Kurzatmigkeit oder Atembeschwerden, manchmal sogar in Ruhe</li>
                        <li className='font-medium'>Engegefühl in der Brust, was das tiefe Atmen erschwert</li>

                    </ul>
                </div>

                <p className='pt-5'>
                    Diese Symptome können mild oder schwer sein und im Laufe der Zeit kommen und gehen. Obwohl Asthma eine ernsthafte Erkrankung sein kann, kann es mit der richtigen Behandlung kontrolliert werden, um die durch die Krankheit auferlegten Einschränkungen zu überwinden. Bei einigen Menschen können sich die
                    Symptome verschlechtern, wenn sie eine Erkältung haben oder sich das Wetter ändert. Weitere Auslöser können Staub, Rauch, Dämpfe, Gräser- und
                    Baumpollen, Tierhaare und -federn, starke Seifen und Parfums sein. Die Symptome können auch durch andere Erkrankungen verursacht werden. <span className='font-medium'>Es ist sehr
                    wichtig, dass Menschen mit Asthmasymptomen einen Arzt konsultieren</span>.<sup>2</sup>

                </p>
                <p className='pt-5'>
                Jeder Mensch kann in jedem Alter Asthma entwickeln.<sup>4</sup> Menschen mit Allergien oder Personen, die Tabakrauch ausgesetzt sind, entwickeln mit höherer
Wahrscheinlichkeit Asthma. Dazu gehört sowohl Passivrauch (Exposition gegenüber dem Rauch anderer) als auch Rauchexposition gegenüber Kleidung oder
Oberflächen in Bereichen, in denen jemand geraucht hat.<sup>5</sup> Statistiken zeigen, dass Frauen eher an Asthma leiden als Männer.<sup>6</sup>
                </p>
                <p className='pt-5 font-medium'>DAS ASTHMA-ZONEN-SYSTEM</p>
                <p>Das Asthma-Zonen-System ist eine Methode, die Menschen hilft, mit ihrem Asthma umzugehen, indem sie ihre Symptome nach Schweregrad in verschiedene
                Zonen unterteilen. Es gibt drei Zonen: grün, gelb und rot.</p>
                <p><span className='font-medium'>Grüne Zone:</span> Die "Alles in Ordnung"-Zone. Ihr Asthma ist unter Kontrolle und Sie können Ihre üblichen Aktivitäten ohne Probleme ausführen.</p>
                <p><span className='font-medium'>Gelbe Zone:</span> Die "Vorsicht"-Zone. Ihr Asthma macht sich bemerkbar und Sie müssen vorsichtig sein. Es ist ein Warnzeichen, auf Ihre Symptome zu achten.</p>
                <p><span className='font-medium'>Rote Zone:</span> Die "Gefahr"-Zone. Ihr Asthma ist schwer und Sie benötigen sofortige Hilfe. Es ist wie ein Stoppzeichen, das Ihnen sagt, sofort zu handeln.</p>

                <p className='pb-5'>Durch das Erkennen, in welcher Zone sie sich befinden, können Asthmatiker geeignete Maßnahmen gemäß ihrem Asthma-Aktionsplan ergreifen, um ihre Symptome effektiv zu managen und ernsthafte Komplikationen zu vermeiden.<sup>7</sup></p>
            </div>
            <hr className=' bg-black text-black' style={{ height: '2px' }} />
            <div className='py-8 text-sm '>
                <p className='font-bold uppercase'>REFERENZEN:</p>
                <ul className=' list-decimal italic text-xs px-3 space-y-1 pt-1 break-all '>
                    <li>Global burden of 369 diseases and injuries in 204 countries and territories, 1990–2019: a systematic analysis for the Global Burden of Disease Study 2019. Lancet. 2020;396(10258):1204-22</li>
                    <li><a href='https://www.who.int/news-room/fact-sheets/detail/asthma' target='_blank' className='underline' >https://www.who.int/news-room/fact-sheets/detail/asthma</a></li>
                    <li>An invisible disease: severe asthma is more than just “bad asthma” <a href='https://erj.ersjournals.com/content/50/3/1701109?ctkey=shareline' target='_blank' className='underline' >https://erj.ersjournals.com/content/50/3/1701109?ctkey=shareline</a></li>
                    <li>Asthma in Adults <a href='https://www.atsjournals.org/doi/10.1513/AnnalsATS.201703-259OC' target='_blank' className='underline' >https://www.atsjournals.org/doi/10.1513/AnnalsATS.201703-259OC</a></li>
                    <li>Thirdhand Tobacco Smoke: Emerging Evidence and Arguments for a Multidisciplinary Research Agenda <a href='https://ehp.niehs.nih.gov/doi/10.1289/ehp.1103500' target='_blank' className='underline' >https://ehp.niehs.nih.gov/doi/10.1289/ehp.1103500</a></li>
                    
                    <li>National Center for Health Statistics. (2023). 2022 NHIS Child Summary Health Statistics. U.S. Department of Health and Human Services. <a href='https://data.cdc.gov/d/wxz7-ekz9' target='_blank' className='underline' >https://data.cdc.gov/d/wxz7-ekz9</a></li>
                    <li>Asthma Action Plan <a href='https://acaai.org/wp-content/uploads/2021/07/asthma_actplan.pdf' target='_blank' className='underline' >https://acaai.org/wp-content/uploads/2021/07/asthma_actplan.pdf</a></li>

                </ul>
            </div>
        </div>



    )
}

export default WhatIsAsthama