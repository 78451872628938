import React from 'react'

const WhatIsCOPD = () => {
    return (
        <div className='pt-4'>
            <p>
                Secondo le più recenti stime, <span className='font-medium'>la BPCO colpisce più di 300 milioni di persone in tutto il mondo</span> ed è previsto che il suo impatto e la sua diffusione aumentino nei prossimi decenni a causa della combinazione tra l’esposizione continua ai <span className='font-medium'>fattori di rischio di BPCO e l'invecchiamento della popolazione mondiale</span>.
                <sup>1,2</sup>
            </p>
            <p>
                È stato anche osservato un <span className='font-medium'>aumento della prevalenza della patologia nella popolazione femminile</span>, dovuto in parte all'aumento del consumo di tabacco tra le donne di tutto il mondo e all'esposizione ai combustibili da biomassa.
                <sup>3 </sup>
                Secondo le ultime stime dell'OMS (Organizzazione mondiale della sanità), <span className='font-medium'>la BPCO è la terza causa di morte a livello mondiale</span>, con
                3.23 milioni di morti nel 2019.<sup>1,2,4</sup>
            </p>
            <p>
                Le mancate diagnosi e le diagnosi errate portano i pazienti a ricevere cure sbagliate o a non ricevere alcun trattamento. Una diagnosi appropriata e precoce della BPCO, quindi, può avere un impatto molto significativo sulla salute pubblica
                <sup>2</sup>.
            </p>
            <div className='pt-5'>
                <span className='font-bold'>LA BPCO IN SINTESI</span>
                <div className='pt-3'>
                    <span className='text-primary font-bold'>BPCO (Broncopneumopatia Cronica Ostruttiva) sta per malattia polmonare ostruttiva cronica</span>, che significa<sup>5</sup> :

                    <ul className='list-disc py-2 px-9'>
                        <li><span className='text-primary font-bold'>B</span>roncopneumo - colpisce i polmoni</li>
                        <li><span className='text-primary font-bold'>P</span>atia - è una condizione medica</li>
                        <li><span className='text-primary font-bold'>C</span>ronica - è una condizione a lungo termine</li>
                        <li><span className='text-primary font-bold'>O</span>struttiva - le vie aeree sono più strette, così da limitare o ostruire il flusso d'aria durante l’espirazione</li>
                    </ul>

                    <div className='py-8 space-y-4 text-light'>
                        <div className=''>
                            Il termine BPCO è usato per descrivere <span className='font-medium'>un gruppo di condizioni polmonari</span> che rendono difficile svuotare i polmoni dai gas che dovrebbero essere espirati. Questo accade quando il <span className='font-medium'>tessuto polmonare, essendo danneggiato, è caratterizzato da vie aeree più strette</span>. Il muco può anche bloccare parzialmente le vie aeree e il rivestimento delle stesse può diventare gonfio e infiammato.
                            Tutti questi fattori <span className='font-medium'>rendono più difficile l’ingresso e l’uscita dell’aria</span> durante la respirazione e rendono <span className='font-medium'>i polmoni meno capaci di introdurre ossigeno</span> ed eliminare l’anidride carbonica.
                            <sup>5</sup>
                        </div>
                        <div>
                            I principali sintomi della BPCO sono <span className='font-medium'>dispnea, e/o tosse con o senza produzione di espettorato</span>; le persone che vivono con <span className='font-medium'>la BPCO possono sperimentare episodi acuti, caratterizzati da un aumento dei sintomi respiratori</span> chiamati “riacutizzazioni”, che influenzano il loro stato di salute, la prognosi e <span className='font-medium'>richiedono specifiche misure preventive e terapeutiche</span>.<sup>2</sup>
                        </div>
                        <div>
                            I pazienti con <span className='font-medium'>BPCO spesso convivono con altre patologie (comorbidità)</span>, che possono influenzare la loro condizione clinica, la prognosi e <span className='font-medium'>richiedono un trattamento specifico</span>.
                            Queste condizioni di comorbidità possono contribuire a scatenare e/o aggravare episodi di riacutizzazioni.

                        </div>
                        <div>
                            Le malattie croniche più frequenti associate alla BPCO includono patologie cardiovascolari e altre malattie respiratorie, osteoporosi, atrofia o ipotrofia muscolare, patologie metaboliche e psichiatriche, malattie renali croniche, reflusso gastroesofageo ed altre.
                        </div>
                        <div>
                            <span className='font-medium'>Le comorbilità possono comparire anche precocemente nella vita delle persone con BPCO</span> e spesso rimangono non diagnosticate o non adeguatamente diagnosticate, oltre che mal gestite o non gestite affatto.
                            Le frequenti comorbilità che colpiscono i pazienti con BPCO non si verificano a caso, ma sono il risultato di sindemie, cioè il verificarsi di gruppi di malattie con fattori di rischio condivisi e interazioni biologiche che aggravano la prognosi e l’impatto della malattia stessa, per gli individui e la società in generale.<sup>6</sup>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WhatIsCOPD