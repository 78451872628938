import React from 'react'

const References = () => {
    return (
        <div className='py-8 text-sm '>
            <p className='font-bold uppercase'>references:</p>
            <ul className=' list-decimal  italic text-xs px-3 space-y-1 pt-1 break-all '>
                <li>Adeloye et al., Lancet Respir Med 2022; 10: 447–58 <a href='https://pubmed.ncbi.nlm.nih.gov/35279265/' target='_blank' className='underline' > https://pubmed.ncbi.nlm.nih.gov/35279265/</a></li>
                <li>GOLD Report 2023<a href='https://goldcopd.org/2023-gold-report-2/' target='_blank' className='underline' > https://goldcopd.org/2023-gold-report-2/</a></li>
                <li>Gut-Gobert et al., Eur Respir Rev 2019; 28: 180055<a href='https://doi.org/10.1183/16000617.0055-2018/' target='_blank' className='underline' > https://doi.org/10.1183/16000617.0055-2018</a></li>
                <li><a href='https://doi.org/10.1183/16000617.0055-2018/' target='_blank' className='underline' > https://www.who.int/news-room/fact-sheets/detail/chronic-obstructive-pulmonary-disease-(copd)</a></li>
                <li>Asthma + Lung UK. What is COPD? Available at:<a href='https://www.asthmaandlung.org.uk/conditions/copd-chronic-obstructive-pulmonary-disease/what-copd/' target='_blank' className='underline' > https://www.asthmaandlung.org.uk/conditions/copd-chronic-obstructive-pulmonary-disease/what-copd</a></li>
                <li>Fabbri et al., Lancet Respir Med 2023<a href='https://doi.org/10.1016/S2213-2600(23)00261-8/' target='_blank' className='underline' >  https://doi.org/10.1016/S2213-2600(23)00261-8</a></li>
                <li>Stolz et al., The Lancet Commissions 2022<a href='https://doi.org/10.1016/S0140-6736(22)01273-9' target='_blank' className='underline' >  https://doi.org/10.1016/S0140-6736(22)01273-9</a></li>

            </ul>
        </div>
    )
}

export default References