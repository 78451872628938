export const BASE_URL = "";

export const DATE_FORMAT_SERVER = "yyyy-MM-dd";
export const DATE_FORMAT_DISPLAY = "dd/MM/yyyy";
export const LANG_ACCESS = "chiesi_breath";
export const LANG_ACCESS_SELECTED = "chiesi_breath_selected";
export const ACCESS_TOKEN = "chiesi_breath_access";


export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";

export const ENGLISH_LANGUAGE = {
    value: "en",
    label: "English",
}
export const ITALIAN_LANGUAGE = {
    value: "it",
    label: "Italiano",
}
export const DUTCH_LANGUAGE = {
    value: "de",
    label: "Deutsch",
}
export const FRENCH_LANGUAGE = {
    value: "fr",
    label: "Français",
}
export const SPANISH_LANGUAGE = {
    value: "es",
    label: "Español",
}
export const LANGUAGES = [
    ENGLISH_LANGUAGE,
    ITALIAN_LANGUAGE,
    DUTCH_LANGUAGE,
    FRENCH_LANGUAGE,
    SPANISH_LANGUAGE
]

// custom styles
export const customStyles = {
    rows: {
        style: {
        minHeight: '60px', // override the row height
        borderBottom: "0.8px solid #ccc",
        }
    },
    headCells:{
        style:{
            color:"#000",
            fontSize:"14px",
            // backgroundColor: "#40e0d0",
            backgroundColor: "#fff",
            fontWeight: "600"
        }
    },
    cells: {
        style: {
           color: "#000",
           fontWeight: "500",
           minWidth:'120px'
        },
    },
};
// custom styles

// custom styles
export const tableStylesSmoll = {
    rows: {
        style: {
        // minHeight: '60px', // override the row height
        borderBottom: "0.8px solid #ccc",
        }
    },
    headCells:{
        style:{
            color:"#000",
            fontSize:"12px",
            // backgroundColor: "#40e0d0",
            backgroundColor: "#fff",
            fontWeight: "700"
        }
    },
    cells: {
        style: {
           color: "#000",
           fontSize:"12px",
           fontWeight: "500",
           minWidth:'120px'
        },
    },
};
// custom styles

export const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
}

export const LISTING_LIMIT = 10;