import React from 'react'
import { FiExternalLink } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const About = () => {
    const language = useSelector(state => state.language.lang);

    return (
        <div className='relative px-5 sm:px-12 pt-3 pb-24 sm:pt-10 sm:pb-[70px] text-justify text-black'>
            <h2 className='text-xl sm:text-3xl lg:text-4xl text-primary font-bold  text-center mb-3 sm:mb-8'>
                ABOUT CHIESI
            </h2>
            <div className='mb-5 sm:mb-6 '>
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    Chiesi is an international, research-focused biopharmaceuticals group that develops and markets innovative
                    therapeutic solutions in respiratory health, rare diseases, and specialty care. The company’s mission is to
                    improve people’s quality of life and act responsibly towards both the community and the environment.
                </p>
            </div>
            <div className="mb-5 sm:mb-6">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    By changing its legal status to a Benefit Corporation in Italy, the US, and France, Chiesi’s commitment
                    to create shared value for society as a whole is legally binding and central to company-wide decisionmaking. As a certified B Corp since 2019, we’re part of a global community of businesses that meet high
                    standards of social and environmental impact. The company aims to reach Net-Zero greenhouse gases
                    (GHG) emissions by 2035.
                </p>


            </div>
            <div className="mb-5 sm:mb-6">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    With over 85 years of experience, Chiesi is headquartered in Parma (Italy), operates in 31 countries, and
                    counts more than 6,500 employees. The Group’s research and development centre in Parma works
                    alongside 6 other important R&D hubs in France, the US, Canada, China, the UK, and Sweden.
                </p>
            </div>
            <div className="mb-5 sm:mb-8">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    For further information, please visit <a href="https://www.chiesi.com" className='underline'>www.chiesi.com</a>
                </p>
            </div>
            <div className='mt-8'>
                <Link target="_blank" to={`/copd/${language.value ? language.value : "en"}/cookie-policy`} className="mt-5 flex items-center space-x-3 text-base sm:text-lg lg:text-xl font-bold">
                    <p>
                        Cookie Policy
                    </p>
                    <FiExternalLink />
                </Link>
            </div>

            <img src="/images/logos/logo-bottom-mobile-1.png" className="-mb-1 block sm:hidden w-full relative z-10 -mt-1" />
            <img src="/images/logos/logo-bottom-desktop.png" className="ml-auto hidden sm:block w-10/12 relative z-10 -mt-1" />
        </div>
    )
}

export default About