import React from 'react'
import { FiExternalLink } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const About = () => {
    const language = useSelector(state => state.language.lang);

    return (
        <div className='relative px-5 sm:px-12 pt-3 pb-24 sm:pt-10 sm:pb-[70px] text-justify text-black'>
            <h2 className='text-xl sm:text-3xl lg:text-4xl text-primary font-bold  text-center mb-3 sm:mb-8'>
                ÜBER CHIESI
            </h2>
            <div className='mb-5 sm:mb-6 '>
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    Chiesi ist eine internationale, forschungsorientierte biopharmazeutische Unternehmensgruppe, die innovative therapeutische Lösungen in den Bereichen Atemwegsgesundheit, seltene Krankheiten und Intensivmedizin entwickelt und vermarktet. Die Mission des Unternehmens ist es, die Lebensqualität der Menschen zu verbessern und gleichzeitig verantwortungsbewusst gegenüber der Gemeinschaft und der Umwelt zu handeln.
                </p>
            </div>
            <div className="mb-5 sm:mb-6">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    Durch die Änderung ihres Rechtsstatus zu einer Benefit Corporation in Italien, den USA und Frankreich ist Chiesis Verpflichtung, einen gemeinsamen Wert für die Gesellschaft als Ganzes zu schaffen, rechtlich bindend und zentral für die unternehmensweite Entscheidungsfindung. Als zertifizierte B Corp seit 2019 sind wir Teil einer globalen Gemeinschaft von Unternehmen, die hohe Standards für soziale und ökologische Auswirkungen erfüllen. Das Unternehmen strebt an, bis 2035 Netto-Null-Emissionen von Treibhausgasen (THG) zu erreichen.
                </p>


            </div>
            <div className="mb-5 sm:mb-6">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    Mit über 85 Jahren Erfahrung hat Chiesi seinen Hauptsitz in Parma (Italien), ist in 31 Ländern tätig und beschäftigt mehr als 6.500 Mitarbeiter. Das Forschungs- und Entwicklungszentrum der Gruppe in Parma arbeitet zusammen mit 6 weiteren wichtigen F&E-Zentren in Frankreich, den USA, Kanada, China, dem Vereinigten Königreich und Schweden.
                </p>
            </div>
            <div className="mb-5 sm:mb-8">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    Für weitere Informationen besuchen Sie bitte <a href="https://www.chiesi.com" className='underline'>www.chiesi.com</a>.
                </p>
            </div>
            <div className='mt-8'>
                <Link target="_blank" to={`/copd/${language.value ? language.value : "en"}/cookie-policy`} className="mt-5 flex items-center space-x-3 text-base sm:text-lg lg:text-xl font-bold">
                    <p>
                        Cookie-Richtlinie
                    </p>
                    <FiExternalLink />
                </Link>
            </div>
            <img src="/images/logos/logo-bottom-mobile-1.png" className="-mb-1 block sm:hidden w-full relative z-10 -mt-1" />
            <img src="/images/logos/logo-bottom-desktop.png" className="ml-auto hidden sm:block w-10/12 relative z-10 -mt-1" />
        </div>
    )
}

export default About