import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ENGLISH_LANGUAGE, FRENCH_LANGUAGE, ITALIAN_LANGUAGE } from '../../constants'
import { set_lang } from '../../redux/slices/languageSlice'
import { setLang } from '../../utils'
import {AiOutlineSound} from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom'

const LangSelect = () => {
    const lang = useSelector(state => state.language.lang)
    const dispatch = useDispatch();
    const navigate=useNavigate()


    const handleLangugae=(lang)=>{
        dispatch(set_lang(lang))
        setLang(lang)
        navigate(`/copd/cookie-policy/${lang.value ? lang.value : "en"}`)
    }
    return (
        <>
            <div className='absolute top-12 md:top-4 right-2 md:right-4 z-[10001] px-8 py-1 md:py-3 rounded-xl bg-white  bg-opacity-25 w-auto flex items-center justify-end space-x-3'>
                <button onClick={() => handleLangugae(ENGLISH_LANGUAGE)} className={`focus:outline-none text-base sm:text-lg font-bold text-black ${lang.value === ENGLISH_LANGUAGE.value ? "font-bold" : "font-light"}`}>
                    EN
                </button>
                <button onClick={() => handleLangugae(ITALIAN_LANGUAGE)} className={`focus:outline-none text-base sm:text-lg font-bold text-black ${lang.value === ITALIAN_LANGUAGE.value ? "font-bold" : "font-light"}`}>
                    IT
                </button>
                <button onClick={() => handleLangugae(FRENCH_LANGUAGE)} className={`focus:outline-none text-base sm:text-lg font-bold text-black ${lang.value === FRENCH_LANGUAGE.value ? "font-bold" : "font-light"}`}>
                    FR
                </button>
            </div>
        </>
    )
}

export default LangSelect