import React, { useEffect, useState } from 'react'

import WhatIsCOPD from './COPD/WhatIsCOPD';
import MainRiskFact from './COPD/MainRiskFact';
import HowToLive from './COPD/HowToLive';

import WhatIsAsthama from './Asthama/WhatisAsthama';
import AsthamaMainRiskFact from './Asthama/MainRiskFact';
import AsthamaHowToLive from './Asthama/HowToLive';

import References from './COPD/References';
import About from './About';
import AreYouCareGiver from './AreYouCareGiver';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { set_tab } from '../../../../redux/slices/tabSlice';
import "react-modal-video/css/modal-video.min.css"
import VideoContainer from '../../../../components/VideoContainer';



const Dutch = () => {
    const { id } = useParams()
    const [isOpen, setOpen] = useState(false);
    const initialTab = id === 'asthma' ? 0 : id === 'copd' ? 1 : 0;
    const [mainTab, setMainTab] = useState(initialTab)
    const [tab, setTab] = useState(0)
    const [active, setActive] = useState(null)

    console.log(id)


    useEffect(() => {
        setActive(null)
    }, [tab, mainTab])



    return (
        <>
            <div className='px-5 sm:px-12 text-justify -mt-5 '>
                <div className='bg-primary py-4 mb-3 sm:mb-4'>
                    <h2 className='text-xl sm:text-3xl lg:text-3xl font-bold text-white text-center'>
                    CHIESIS OFFENER DIALOG MIT PATIENT:INNEN
                    </h2>
                </div>

                <div className='mb-5'>
                    <span className="text-base sm:text-lg font-bold lg:text-xl leading-1 sm:leading-tight ">
                        Der ständige Dialog mit Menschen, die an Atemwegserkrankungen wie Asthma und COPD leiden, ist die Grundlage unseres Ansatzes.
                    </span>
                    <p className="text-base sm:text-lg lg:text-xl  leading-1 sm:leading-tight ">
                        Das Hauptziel der Kampagne ist es, das Bewusstsein für Themen im Zusammenhang mit dem Wohlbefinden von Menschen mit Atemwegserkrankungen zu schärfen,
                        indem wir nützliche Informationen für Patient:innen und Angehörige teilen. <span className='font-bold'>Es ist möglich, über die durch gesundheitliche Einschränkungen auferlegten Grenzen
                            hinauszugehen und ein Leben voller unbegrenzter Möglichkeiten zu genießen</span>.
                    </p>

                </div>
            </div>
            <div>
                <SelectionMainTab mainTab={mainTab} setMainTab={setMainTab} setActive={setActive} setTab={setTab} />
                {mainTab === 1 ?
                    <>
                        <div className='pt-4 xl:pt-0  px-5 sm:px-12'>
                            <div className='px-4 mt-5  md:mt-10 mb-5 text-xl sm:text-2xl lg:text-3xl font-bold text-primary text-center'>
                                Entdecken Sie Felicitys Reise der grenzenlosen Entschlossenheit im Umgang mit COPD</div>
                            <div className="relative flex flex-col justify-betwee aspect-video" >
                                <VideoContainer
                                    url={"https://ers-dev.s3.eu-west-3.amazonaws.com/media/FELICITY_PAYNE_DEU_V2_1.mp4"}
                                />
                            </div>


                            <div className='mt-5  md:mt-10 mb-10 sm:mb-8'>
                                <p className=" px-4 text-base sm:text-lg lg:text-xl leading-tight">
                                    Hier finden Sie weitere Informationen über COPD sowie nützliche Vorschläge, wie das tägliche Leben der Betroffenen verbessert werden kann.
                                </p>
                                <p className='px-4 text-base sm:text-lg lg:text-xl leading-tight'>
                                    <strong>Das Leitprinzip ist, Patient:innen bei ihren täglichen Aktivitäten zu unterstützen, um ihre Lebensqualität zu erhöhen.</strong>
                                </p>
                            </div>
                        </div>
                        <SelectionTab tab={tab} setTab={setTab} setActive={setActive} mainTab={mainTab} />
                        <div className='pt-4 xl:pt-0  px-5 sm:px-12'>

                            <div className='px-5 text-justify sm:px-12 py-6 space-y-2 text-base sm:text-lg lg:text-xl leading-tight  font-light bg-gray-200 '>
                                {tab === 0 ?
                                    <WhatIsCOPD />
                                    : tab === 1 ?
                                        <MainRiskFact />
                                        : tab == 2 ?
                                            <HowToLive active={active} setActive={setActive} />
                                            : ""}

                                {tab != 2 &&
                                    <>
                                        <hr className=' bg-black text-black' style={{ height: '2px' }} />
                                        <References tab={tab} />
                                    </>}

                            </div>

                        </div></>
                    : <>
                        <div className='pt-4 xl:pt-0  px-5 sm:px-12'>
                            <div className='px-4 mt-5  md:mt-10 mb-5 text-xl sm:text-2xl lg:text-3xl font-bold text-primary text-center'>
                                Entdecken Sie Giovannis Reise der grenzenlosen Entschlossenheit im Umgang mit Asthma</div>
                            <div className="relative flex flex-col justify-betwee aspect-video" >
                               <VideoContainer
                                url={"https://ers-dev.s3.eu-west-3.amazonaws.com/media/GIOVANNI_QUAGLIA_+DEU_V1_1.mp4"}
                               />
                            </div>


                            <div className='mt-5  md:mt-10 mb-10 sm:mb-8'>
                                <p className=" px-4 text-base sm:text-lg lg:text-xl leading-tight">
                                    Hier finden Sie weitere Informationen über Asthma sowie nützliche Vorschläge, wie das tägliche Leben der Betroffenen verbessert werden kann.
                                </p>
                                <p className='px-4 text-base sm:text-lg lg:text-xl leading-tight'>
                                    <strong>Das Leitprinzip ist, Patient:innen bei ihren täglichen Aktivitäten zu unterstützen, um ihre Lebensqualität zu erhöhen.</strong>
                                </p>
                            </div>
                        </div>
                        <SelectionTab tab={tab} setTab={setTab} setActive={setActive} /><div className='pt-4 xl:pt-0  px-5 sm:px-12'>

                            <div className='px-5 text-justify sm:px-12 py-6 space-y-2 text-base sm:text-lg lg:text-xl leading-tight  font-light bg-gray-200 '>
                                {tab === 0 ?
                                    <WhatIsAsthama />
                                    : tab === 1 ?
                                        <AsthamaMainRiskFact />
                                        : tab == 2 ?
                                            <AsthamaHowToLive active={active} setActive={setActive} />
                                            : ""}

                            </div>

                        </div></>}
                <div className='pt-4 xl:pt-0  px-5 sm:px-12'>
                    <AreYouCareGiver mainTab={mainTab == 1 ? "COPD" : "ASTHAMA"} />
                </div>
            </div>

            <About />

        </>
    )
}

export default Dutch





export const SelectionTab = ({ tab, setTab, setActive, mainTab }) => {

    return (
        <>
            <div className='px-5 sm:px-12  grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 '>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${tab === 0 ? "bg-gray-200  " : "border-2"} border-2   p-4 uppercase text-primary font-medium`} onClick={() => setTab(0)}>
                        {mainTab == 1 ? "WAS IST COPD" : "WAS IST ASTHMA?"}
                    </div>
                </div>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${tab === 1 ? "bg-gray-200" : "border-2"}  border-2 p-4 uppercase text-primary font-medium `} onClick={() => setTab(1)}>
                        HAUPTRISIKOFAKTOREN
                    </div>
                </div>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${tab === 2 ? "bg-gray-200" : "border-2"}  border-2 p-4 uppercase text-primary font-medium`} onClick={() => setTab(2)}>
                        {/* HOW TO LIVE WITH COPD */}

                        {mainTab == 1 ? "LEBEN MIT COPD" : "MIT ASTHMA LEBEN"}
                    </div>

                </div>
            </div>
            <div className={`  hidden xl:block    px-5 sm:px-12  xl:grid xl:grid-cols-3 gap-3`}>
                <div className={`${tab === 0 ? " h-4 bg-gray-200 " : ""}`} ></div>
                <div className={`${tab === 1 ? "h-4 bg-gray-200" : ""}`} ></div>
                <div className={`${tab === 2 ? "h-4 bg-gray-200" : ""}`} ></div>
            </div>

        </>
    )
}


export const SelectionMainTab = ({ mainTab, setMainTab, setMainActive, setTab }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <>
            <div className='px-5 sm:px-12  grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 gap-2 mb-4'>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${mainTab === 0 ? "bg-primary text-white  " : "border-2"} border-2  border-primary p-4 uppercase text-primary font-medium`} onClick={() => {
                        setMainTab(0)
                        setTab(0)
                        dispatch(set_tab("asthma"))
                        navigate('/copd/de/asthma');
                    }}>
                        ASTHMA
                    </div>
                </div>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${mainTab === 1 ? "bg-primary text-white" : "border-2"}  border-2 border-primary p-4 uppercase text-primary font-medium `} onClick={() => {
                        setMainTab(1)
                        setTab(0)
                        dispatch(set_tab("copd"))
                        navigate('/copd/de/copd');
                    }}>
                        COPD
                    </div>
                </div>
            </div>

        </>
    )
}