export const ArrayCard=[
    {
        "title":<div className=""><div>
                PRÉSERVEZ VOTRE 
            </div>
            <div>
                SANTÉ
            </div>
        </div>,
        "slogan":"Évitez la fumée",
        "path":"/images/cigarette.png",
        "custom_width":null,
        slogan2:true
    
    },
    {
        "title":"SOYEZ ACTIF",
        "slogan":"Pratiquez une activité physique",
        "path":"/images/weight_liffter.png",
        "custom_width":"w-[100%]",

    },
    {
        "title":"GARDEZ LA FORME",
        "slogan":"Mangez équilibré",
        "path":"/images/spoon.png",
        "custom_width":"w-[130%]"
    
    },
    {
        "title":<div className="">
             <div>SENTEZ-VOUS LIBRE</div>
             <div>DE VOUS DÉPLACER</div>
        </div>,
        "slogan":"Continuez à explorer",
        "path":"/images/aeroplan.png",
        "custom_width":null,
        slogan2:true
    },
    {
        "title":"RESTEZ EN CONTACT",
        "slogan":<div className="leading-tight mt-1">
            <div>Sachez reconnaître la dépression,</div>
            <div className="">
            l'anxiété, la panique
            </div>    
        </div>,
        "path":"/images/emojies.png",
        "custom_width":"w-[100%]"    
    }
]


export const DetailArrayCard =[
    {
        "title":"PRÉSERVEZ VOTRE SANTÉ",
        "slogan":"Évitez la fumée",
        "path":"/images/cigarette.png",
        "custom_width":null,
        "custom_classes":"lg:mt-12",
        "description":<div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                Il est bien connu que le tabagisme, y compris l'utilisation de cigarettes électroniques, <span className="font-medium">est néfaste à la fois pour votre santé et pour le bien-être de votre entourage</span>. 
                Dans le cas des personnes souffrant de maladies respiratoires, <span className="font-medium">le tabagisme peut être particulièrement nocif</span>, car il constitue un facteur important du développement et d’aggravation de la BPCO. 
                La recherche a montré que <span className="font-medium">le fait d'arrêter de fumer peut réduire de manière significative le risque d'hospitalisation et de mortalité chez les patients atteints de BPCO</span>.<sup>1</sup>
                </div>

                <div>
                <span className="font-medium">Si vous pensez avoir besoin d'aide pour arrêter de fumer, vous pouvez explorer différentes méthodes</span>. 
                Trois types de conseils se sont révélés particulièrement efficaces : <span className="font-medium">les conseils pratiques, 
                le soutien de la famille et des amis</span> dans le cadre du processus de traitement et la recherche d'un <span className="font-medium">soutien social en dehors du cadre formel du traitement</span>.<sup>2</sup>
                </div>
        </div>,
        "references":
            <ul className='list-decimal'>
                <li>
                    Global Allergy and Airways Patients Platform: <span className='underline'><a href='https://it.gaapp.org/diseases/copd/copd-and-smoking/' target='_blank' >https://it.gaapp.org/diseases/copd/copd-and-smoking/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 111
                </li>
         </ul>
        
    
    },
    {
        "title":"SOYEZ ACTIF",
        "slogan":"Pratiquez une activité physique",
        "path":"/images/weight_liffter.png",
        "custom_width":"w-[100%]", //for below line
        "custom_classes":"lg:mt-12",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className="font-medium">Il est bien connu qu'une activité physique</span>, même légère, <span className="font-medium">peut être très bénéfique pour les personnes de tous âges et de toutes conditions</span>.<sup>1 </sup>
                    Et il n'est pas nécessaire de battre des records tous les jours pour prendre soin de son système respiratoire. 
                    La recherche montre que les personnes atteintes de BPCO pratiquent souvent moins d'activité physique<sup>2</sup>,
                    alors que <span className="font-medium">rester actif est essentiel pour le bien-être général</span>. Il est important d’avoir une vie active pour rester en bonne santé. 
                    Pensez à <span className="font-medium">intégrer une promenade quotidienne agréable dans votre routine</span> : saviez-vous qu’il suffit de <span className="font-medium">600 pas par jour 
                    pour améliorer la qualité de la respiration</span><sup>3 </sup>
                    chez les personnes atteintes de BPCO ?
                </div>
                <div>
                    <span className="font-medium">Préférez les escaliers</span> à l'ascenseur, <span className="font-medium">faites un peu de jardinage ou de natation</span> lorsque c'est possible. 
                    Lorsqu'on pratique une activité physique, <span className="font-medium">il est essentiel de le faire correctement, même s'agissant d'une activité légère</span>. 
                    Commencez par vous <span className="font-medium">étirer ou vous échauffer</span>, <span className="font-medium">hydratez-vous</span>, prenez votre temps et <span className="font-medium">assurez-vous que vos médicaments soient à portée de main</span>.
                </div>
            </div>,
        "references":
            <ul className='list-decimal'>
                <li>
                    European Lung Foundation: <span className='underline'><a href='https://europeanlung.org/it/information-hub/factsheets/vivere-una-vita-attiva-con-la-bpco/' target='_blank' >https://europeanlung.org/it/information-hub/factsheets/vivere-una-vita-attiva-con-la-bpco/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 122
                </li>
                <li>
                    Better Health Channel: <span className='underline'><a href='https://www.betterhealth.vic.gov.au/health/healthyliving/walking-for-good-health' target='_blank' >https://www.betterhealth.vic.gov.au/health/healthyliving/walking-for-good-health</a></span>
                </li>
            </ul>
    },
    {
        "title":"GARDEZ LA FORME",
        "slogan":"Mangez équilibré",
        "path":"/images/spoon.png",
        "custom_width":"w-[130%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                <span className="font-medium">Votre alimentation joue un rôle majeur dans le maintien d'une respiration saine</span>. 
                Le poids peut avoir un impact significatif sur la fonction respiratoire et être la cause d’une aggravation de vos symptômes. 
                En outre, il est important de noter que de nombreux patients atteints de BPCO présentent des symptômes de malnutrition protéino-énergétique. 
                <span className="font-medium">La solution réside dans le maintien d'une alimentation équilibrée</span>.
                </div>
                <div>
                ll faut tout d’abord <span className="font-medium">limiter la consommation de sel, réduire la consommation de graisses</span> (en optant pour des aliments riches en oméga 3, qui favorisent la santé des poumons), <span className="font-medium">consommer de grandes quantités de fruits</span> pour garantir un taux suffisant de vitamines, de minéraux essentiels, et <span className="font-medium">s'assurer qu'une bonne source de protéines</span> (viandes, poissons, œufs, certaines légumineuses comme les pois chiches, etc…) <span className="font-medium">est incluse dans les repas au moins deux fois par jour</span>.
                </div>
            </div>,
        "references":
            <ul className=''>
                <li>
                    American Lung Association: <span className='underline'><a href='https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/nutrition' target='_blank' >https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/nutrition/</a></span>
                </li>
            </ul>,
        "reference_length":1
        
    
    },
    {
        "title":"SENTEZ-VOUS LIBRE DE VOUS DÉPLACER ",
        "slogan":"Continuez à explorer",
        "path":"/images/aeroplan.png",
        "custom_width":null,
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className='font-medium'>Le fait d'être atteint d'une maladie respiratoire chronique ne doit pas vous empêcher de voyager<sup className='font-light'>1</sup>, rendre visite à vos proches ou profiter de vos vacances. </span>
                    Toutefois, il est essentiel d'effectuer des recherches préalables. <span className="font-medium">Prenez toujours en considération le climat de votre destination</span>, en tenant compte de <span className="font-medium">la température</span> (les symptômes de la BPCO peuvent s'aggraver dans les environnements chauds), de <span className="font-medium">l'altitude</span> (les altitudes plus élevées ont des niveaux d'oxygène plus faibles, consultez donc votre médecin avant de voyager dans des zones montagneuses) et de <span className="font-medium">la qualité de l'air en termes de pollution et d'allergènes</span>
                   <sup>2</sup>.
                </div>
                <div>
                ll convient de noter que même si votre oxygénation au repos est normale, vous pouvez encore souffrir d'hypoxémie sévère lorsque vous voyagez en avion<sup>3</sup>.
                Par conséquent, <span className="font-medium">si vous prévoyez de prendre l'avion, il est conseillé de consulter votre médecin et d'informer la compagnie aérienne</span> de votre état de santé au moment de l'achat de votre billet. Ils peuvent vous assurer un vol sûr et confortable avec des aménagements appropriés.

                </div>
            </div>,
        "references":
            <ul className='list-decimal'>
                <li>
                    COPD Foundation: <span className='underline'><a href='https://www.copdfoundation.org/Learn-More/I-am-a-Person-with-COPD/Traveling-with-COPD.aspx' target='_blank' >https://www.copdfoundation.org/Learn-More/I-am-a-Person-with-COPD/Traveling-with-COPD.aspx</a></span>
                </li>
                <li>
                    European Lung Foundation: <span className='underline'><a href='https://europeanlung.org/en/information-hub/factsheets/living-an-active-life-with-copd/' target='_blank' >https://europeanlung.org/en/information-hub/factsheets/living-an-active-life-with-copd/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 81
                </li>
            </ul>
    },
    {
        "title":"RESTEZ EN CONTACT",
        "slogan":"Sachez reconnaître la dépression, l'anxiété, la panique",
        "path":"/images/emojies.png",
        "custom_width":"w-[100%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                <span className="font-medium">La dépression et l'anxiété sont plus fréquentes</span> chez les personnes atteintes de BPCO que dans la population générale. 
                Ces maladies peuvent être difficiles à reconnaître, à accepter et à traiter, surtout au début. <span className="font-medium">La première étape fondamentale consiste à les reconnaître</span>. 
                Si vous pensez que votre BPCO a un impact sur votre bien-être mental, <span className="font-medium">engagez une conversation avec votre médecin et autres professionnels de santé</span>. N'oubliez pas que la santé mentale est tout aussi essentielle que la santé physique, et que votre médecin peut vous aider à identifier les problèmes et à élaborer un plan de traitement si nécessaire. 
                </div>
                <div>
                <span className="font-medium">Il est essentiel que vous sachiez que vous n'êtes pas seul</span>. Cherchez le soutien de vos proches et n'hésitez pas à exprimer vos émotions. 
                <span className="font-medium">La mise en relation avec d'autres patients atteints de BPCO peut également être bénéfique</span>, car elle permet de partager des expériences et d'apprendre les uns des autres. <span className="font-medium">Consultez les associations de patients pour obtenir plus d'informations et de soutien</span>.
                </div>
            </div>,
        
        "references":
            <ul className=''>
                <li>
                    American Lung Association: <span className='underline'><a href='https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/coping-with-emotions/' target='_blank' >https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/coping-with-emotions</a></span>
                </li>

            </ul>,
        "reference_length":1
        
    }
]