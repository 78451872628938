import React from 'react'

const WhatIsAsthama = () => {
    return (
        <div className='pt-4'>
            <p>
                Asthma is a chronic lung disease affecting <span className='font-medium'>262 million people</span> of all ages worldwide.
                <sup>1</sup></p>
            <p >It is caused by inflammation and muscle tightening around the airways, which makes it <span className='font-medium'>harder to breathe</span>.</p>
            <p className='pt-5'>
                Particularly in low- and middle-income countries, asthma is often underdiagnosed and undertreated, potentially leading to more serious injuries for those
                affected by this condition.<sup>2</sup> Furthermore, asthma is an “invisible” disease, in the sense that it is not visible from the outside, although it can limit or challenge a
                person’s movements, senses or activities.<sup>3</sup>
            </p>
            <div className='pt-5'>

                <p>People with undertreated asthma can suffer sleep disturbance, daytime fatigue, and poor concentration. Asthma sufferers and their families may miss
                    school and work, with a financial impact on the family and wider community. If symptoms are severe, people with asthma may need to receive emergency health
                    care and they may be admitted to the hospital for treatment and monitoring. In the most severe cases, asthma can lead to death.<sup>2</sup> 
                    </p>

            <div className='pt-5'>
            Therefore, it`s crucial not to underestimate the common symptoms, which can include<sup>2</sup>:
            </div>
                <ul className='list-disc py-2 px-9'>
                    <li className='font-medium'>A persistent cough, especially at night</li>
                    <li className='font-medium'>Wheezing when exhaling and sometimes when inhaling</li>
                    <li className='font-medium'>Shortness of breath or difficulty breathing, sometimes even when resting</li>
                    <li className='font-medium'>Chest tightness, making it difficult to breathe deeply.</li>

                </ul>
                <p className='pt-5'>
                    These symptoms can be mild or severe and can come and go over time. Although asthma can be a serious condition, it can be managed with the right
                    treatment, to overcome the limitations imposed by the disease.
                    Some people experience worse symptoms when they have a cold or during changes in the weather. Other triggers can include dust, smoke, fumes, grass and tree
                    pollen, animal fur and feathers, strong soaps, and perfume.
                
                    Symptoms can also be caused by other conditions. <span className='font-medium'>It is essential for people experiencing asthma symptoms to consult a specialized healthcare provider</span>.<sup>2</sup>
                </p>
                <p className='pt-5'>
                    Anyone can develop Asthma at any age.<sup>4</sup> People with allergies or those exposed to tobacco smoke are more likely to develop Asthma. This includes
                    secondhand smoke (exposure to someone else who is smoking) and thirdhand smoke (exposure to clothing or surfaces in places where someone has smoked).<sup>5</sup> Statistics show that females tend to have asthma more frequently than males.<sup>6</sup> Lastly, asthma is more prevalent among African American individuals compared to other ethnic groups.<sup>7</sup>
                </p>
                <p className='pt-5 font-medium'>THE ASTHMA ZONE SYSTEM</p>
                <p className='pt-5'>The asthma zone system is a method used to help people manage their asthma by dividing their symptoms into different zones based on severity. There are
                    three zones: green, yellow, and red.</p>
                <p><span className='font-medium'>Green Zone:</span> This is the "good to go" zone. Your asthma is under control, and you can do your usual activities without any trouble.</p>
                <p><span className='font-medium'>Yellow Zone:</span> This is the "caution" zone. Your asthma is acting up a bit, and you need to be careful. It's like a warning sign to pay attention to your symptoms.</p>
                <p><span className='font-medium'>Red Zone:</span> This is the "danger" zone. Your asthma is severe, and you need help right away. It's like a stop sign telling you to act immediately.</p>

                <p className='pb-5'>By recognizing which zone they're in, individuals with asthma can take appropriate actions outlined in their asthma action plan to manage their symptoms
                    effectively and prevent serious complications.<sup>8</sup></p>
            </div>
            <hr className=' bg-black text-black' style={{ height: '2px' }} />
            <div className='py-8 text-sm '>
                <p className='font-bold uppercase'>references:</p>
                <ul className=' list-decimal text-xs px-3 space-y-1 pt-1 break-all italic'>
                    <li>Global burden of 369 diseases and injuries in 204 countries and territories, 1990–2019: a systematic analysis for the Global Burden of Disease Study 2019. Lancet. 2020;396(10258):1204-22</li>
                    <li><a href='https://www.who.int/news-room/fact-sheets/detail/asthma' target='_blank' className='underline' >https://www.who.int/news-room/fact-sheets/detail/asthma</a></li>
                    <li>An invisible disease: severe asthma is more than just “bad asthma” <a href='https://erj.ersjournals.com/content/50/3/1701109?ctkey=shareline' target='_blank' className='underline' >https://erj.ersjournals.com/content/50/3/1701109?ctkey=shareline</a></li>
                    <li>Asthma in Adults <a href='https://www.atsjournals.org/doi/10.1513/AnnalsATS.201703-259OC' target='_blank' className='underline' >https://www.atsjournals.org/doi/10.1513/AnnalsATS.201703-259OC</a> </li>
                    <li>Thirdhand Tobacco Smoke: Emerging Evidence and Arguments for a Multidisciplinary Research Agenda <a href='https://ehp.niehs.nih.gov/doi/10.1289/ehp.1103500' target='_blank' className='underline' >https://ehp.niehs.nih.gov/doi/10.1289/ehp.1103500</a></li>
                    <li>National Center for Health Statistics. (2023). 2022 NHIS Child Summary Health Statistics. U.S. Department of Health and Human Services. <a href='https://data.cdc.gov/d/wxz7-ekz9' target='_blank' className='underline' >https://data.cdc.gov/d/wxz7-ekz9</a></li>
                    <li>Asthma and African Americans. (2021). <a href='https://minorityhealth.hhs.gov/omh/browse.aspx?lvl=4&lvlid=15#' target='_blank' className='underline' >https://minorityhealth.hhs.gov/omh/browse.aspx?lvl=4&lvlid=15#</a></li>
                    <li>Asthma Action Plan <a href='https://acaai.org/wp-content/uploads/2021/07/asthma_actplan.pdf' target='_blank' className='underline' >https://acaai.org/wp-content/uploads/2021/07/asthma_actplan.pdf</a></li>

                </ul>
            </div>
        </div>



    )
}

export default WhatIsAsthama