export const ArrayCard = [
    {
        "title": "GESUND BLEIBEN",
        "slogan": <div className="leading-tight mt-1">
        <div>RAUCH VERMEIDEN/MIT DEM</div>
        <div className="">
        RAUCHEN AUFHÖREN
        </div></div>,
        "path": "/images/cigarette.png",
        "custom_width": null

    },
    {
        "title": "AKTIV BLEIBEN",
        "slogan": <div className="leading-tight mt-1">
            <div>KÖRPERLICH AKTIV SEIN</div>
            {/* <div className="">
                AUSÜBEN
            </div> */}
        </div>,
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]",

    },
    {
        "title": "FIT BLEIBEN",
        "slogan": "AUSGEWOGEN ESSEN",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]"

    },
    {
        "title": "FREI BEWEGEN",
        "slogan": "WEITER ENTDECKEN",
        "path": "/images/aeroplan.png",
        "custom_width": null
    },
    {
        "title": "VERBUNDEN SEIN",
        "slogan": <div className="leading-tight mt-1">
            <div>DEPRESSION, ANGST UND </div>
            <div className="">
                PANIK ERKENNEN
            </div>
        </div>,
        "path": "/images/Connected.png",
        "custom_width": "w-[100%]"
    }
]


export const DetailArrayCard = [
    {
        "title": "GESUND BLEIBEN",
        "slogan": "RAUCH VERMEIDEN/MIT DEMRAUCHEN AUFHÖREN",
        "path": "/images/cigarette.png",
        "custom_width": null,
        "custom_classes": "lg:mt-12",
        "description": <div className=' text-justify leading-tight font-light  '>
            <div>
                Es ist allgemein anerkannt, dass Rauchen, einschließlich der Verwendung von elektronischen Zigaretten, <span className="font-medium">sowohl Ihrer eigenen Gesundheit als auch dem Wohlbefinden der Menschen um Sie herum schadet. Für Personen mit Atemwegserkrankungen kann Rauchen jedoch besonders schädlich sein</span>, da es ein Hauptfaktor für die Entwicklung von COPD ist. Forschungen haben gezeigt, <span className="font-medium">dass das ein Rauchstopp das Risiko von Krankenhausaufenthalten und Sterblichkeit bei COPD-Patient:innen erheblich verringern kann</span>.<sup>1</sup>
            </div>

            <div>
                <span className="font-medium">Wenn Sie Hilfe beim Aufhören benötigen, gibt es verschiedene Möglichkeiten, die Sie erkunden können</span>. Drei Arten der Beratung haben sich als besonders wirksam erwiesen: <span className="font-medium">praktische Beratung, Unterstützung durch Familie</span> und Freunde als Teil des Behandlungsprozesses sowie die Suche nach <span className="font-medium">sozialer Unterstützung außerhalb formaler Behandlungseinrichtungen</span>.<sup>2</sup>
            </div>
        </div>,
        "references":
            <ul className='list-decimal italic'>
                <li>
                    Global Allergy and Airways Patients Platform: <span className='underline'><a href='https://it.gaapp.org/diseases/copd/copd-and-smoking/' target='_blank' >https://it.gaapp.org/diseases/copd/copd-and-smoking/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 111
                </li>
            </ul>


    },
    {
        "title": "AKTIV BLEIBEN",
        "slogan": "KÖRPERLICH AKTIV SEIN",
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]", //for below line
        "custom_classes": "lg:mt-12",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    Es ist allgemein bekannt, dass selbst leichte körperlichen <span className="font-medium">Aktivität Menschen jeden Alters und in jeder Verfassung erheblich zugutekommen kann</span>.<sup>1 </sup>  Sie müssen sich nicht jeden Tag bis an die Grenzen belasten, um Ihre Lungengesundheit zu pflegen. Die Forschung zeigt, dass Personen mit COPD häufig 
weniger körperlich aktiv sind.<sup>2</sup> <span className="font-medium">Es ist entscheidend, aktiv zu bleiben, um das allgemeine Wohlbefinden zu erhalten und um eine gute Gesundheit zu bewahren</span>. Überlegen Sie, ob Sie einen angenehmen täglichen <span className="font-medium">Spaziergang in Ihre Routine einbauen: Wussten Sie, dass bereits 30 Minuten zügiges 
Gehen die Lungengesundheit bei Menschen mit COPD verbessern können?</span><sup>3 </sup>
                </div>
                <div>
                    <span className="font-medium">Wählen Sie die Treppe anstelle des Aufzugs, machen Sie etwas leichte Gartenarbeit oder schwimmen Sie wann immer möglich</span>. Wenn Sie sich 
körperlich betätigen, <span className="font-medium">ist es wichtig, dies richtig zu tun, selbst wenn es sich um eine leichte Aktivität handelt</span>. Beginnen Sie mit <span className="font-medium">Dehnübungen oder 
Aufwärmen, trinken Sie genug Wasser</span>, nehmen Sie sich Zeit, stellen Sie sicher, <span className="font-medium">dass Ihre Medikamente griffbereit sind, und besprechen Sie mit Ihrer 
Ärztin/Ihrem Arzt die beste Lösung für Ihre allgemeine Verfassung</span>.
                </div>
            </div>,
        "references":
            <ul className='list-decimal italic'>
                <li>
                    European Lung Foundation: <span className='underline'><a href='https://europeanlung.org/it/information-hub/factsheets/vivere-una-vita-attiva-con-la-bpco/' target='_blank' >https://europeanlung.org/it/information-hub/factsheets/vivere-una-vita-attiva-con-la-bpco/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 122
                </li>
                <li>
                    Better Health Channel: <span className='underline'><a href='https://www.betterhealth.vic.gov.au/health/healthyliving/walking-for-good-health' target='_blank' >https://www.betterhealth.vic.gov.au/health/healthyliving/walking-for-good-health</a></span>
                </li>
            </ul>
    },
    {
        "title": "FIT BLEIBEN",
        "slogan": "AUSGEWOGEN ESSEN",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className="font-medium">Ihre Ernährung spielt eine entscheidende Rolle bei der Unterstützung einer gesunden Atmung</span>. Das Körpergewicht kann die Lungenfunktion erheblich beeinflussen und COPD-Symptome verschlimmern. <span className="font-medium">Es ist auch wichtig zu beachten, dass viele COPD-Patient:innen Symptome einer Protein-Energie-Mangelernährung erleben. Die Lösung liegt in einer ausgewogenen Ernährung</span>.
                </div>
                <div>
                    Dazu gehört, <span className="font-medium">den Salzkonsum zu begrenzen, den Verzehr von Fetten zu reduzieren, ausreichende Mengen an Obst für essentielle Vitamine und Mineralien</span> zu sich zu nehmen und sicherzustellen, dass Ihre Mahlzeiten eine <span className="font-medium">gute Proteinquelle enthalten</span>. Dieser Ansatz trägt zur Erhaltung starker Atemmuskeln bei.<sup>1</sup>
                </div>
            </div>,

        "references":
            <ul className='ml-4 list-decimal italic'>
                <li>
                    American Lung Association: <span className='underline'><a href='https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/nutrition' target='_blank' >https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/nutrition/</a></span>
                </li>
            </ul>,
        "reference_length": 1


    },
    {
        "title": "FREI BEWEGEN",
        "slogan": "WEITER ENTDECKEN",
        "path": "/images/aeroplan.png",
        "custom_width": null,
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className="font-medium">Eine chronische Atemwegserkrankung sollte Ihre Fähigkeit, zu reisen, geliebte Menschen zu besuchen oder Urlaube zu genießen, nicht beeinträchtigen</span>.<sup className='font-light'>1</sup> Es ist jedoch wichtig, vorher einige Nachforschungen anzustellen. <span className="font-medium">Berücksichtigen Sie immer das Klima Ihres Reiseziels</span>, insbesondere <span className="font-medium">die Temperatur</span> (da sich COPD-Symptome in heißen Umgebungen verschlimmern können), <span className="font-medium">die Höhe</span> (höhere Lagen haben niedrigere Sauerstoffwerte, daher konsultieren Sie Ihre Ärztin/Ihren Arzt, bevor Sie in Bergregionen reisen) und <span className="font-medium">die Luftqualität in Bezug auf Verschmutzung und Allergene</span>.
                    <sup>2</sup> Es ist wichtig zu beachten, dass selbst wenn Ihre Sauerstoffsättigung in Ruhe auf Meereshöhe normal ist, Sie dennoch einen Sauerstoffmangel (schwere Hypoxämie) beim Fliegen erleben können.<sup>3</sup> <span className="font-medium">Daher ist es ratsam, vor einer Flugreise Ihre Ärztin/Ihren Arzt zu konsultieren und die Fluggesellschaft bei der Buchung Ihres Tickets über Ihre Erkrankung zu informieren</span>. So kann sichergestellt werden, dass Sie einen sicheren und komfortablen Flug mit geeigneten Vorkehrungen haben.

                </div>
            </div>,
        "references":
            <ul className='list-decimal italic'>
                <li>
                    COPD Foundation: <span className='underline'><a href='https://www.copdfoundation.org/Learn-More/I-am-a-Person-with-COPD/Traveling-with-COPD.aspx' target='_blank' >https://www.copdfoundation.org/Learn-More/I-am-a-Person-with-COPD/Traveling-with-COPD.aspx</a></span>
                </li>
                <li>
                    European Lung Foundation: <span className='underline'><a href='https://europeanlung.org/en/information-hub/factsheets/living-an-active-life-with-copd/' target='_blank' >https://europeanlung.org/en/information-hub/factsheets/living-an-active-life-with-copd/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 81
                </li>
            </ul>
    },
    {
        "title": "VERBUNDEN SEIN",
        "slogan": "DEPRESSION, ANGST UND PANIK ERKENNEN",
        "path": "/images/Connected.png",
        "custom_width": "w-[100%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className="font-medium">Depression und Angst sind bei Personen mit COPD häufiger als in der Allgemeinbevölkerung</span>. Diese Herausforderungen können schwer zu erkennen, zu akzeptieren und zu behandeln sein, besonders zu Beginn der Erkrankung. <span className="font-medium">Der erste entscheidende Schritt ist, sie anzuerkennen</span>. Wenn Sie glauben, dass Ihre COPD Ihr psychisches Wohlbefinden beeinträchtigt, <span className="font-medium">beginnen Sie ein Gespräch mit Ihrem Arzt oder Ihrer Ärztin</span>. Denken Sie daran, dass die psychische Gesundheit genauso wichtig ist wie die körperliche Gesundheit, und Ihr Arzt oder Ihre Ärztin kann Ihnen helfen, Probleme zu erkennen und gegebenenfalls einen Behandlungsplan zu entwickeln.

                </div>
                <div>
                    <span className="font-medium">Es ist wichtig zu erkennen, dass Sie nicht allein sind</span>. Suchen Sie Unterstützung bei Ihren Lieben und zögern Sie nicht, Ihre Gefühle zu zeigen. <span className="font-medium">Der Kontakt zu anderen COPD-Patient:innen kann ebenfalls von Vorteil sein</span>, da Sie Erfahrungen austauschen und voneinander lernen können. <span className="font-medium">Informieren Sie sich über lokale Patientenorganisationen für weitere Informationen und Unterstützung</span>.<sup>1</sup>                </div>
            </div>,


        "references":
            <ul className='ml-4 list-decimal italic'>
                <li>
                    American Lung Association: <span className='underline'><a href='https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/coping-with-emotions/' target='_blank' >https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/coping-with-emotions</a></span>
                </li>

            </ul>,
        "reference_length": 1

    }
]
export const AsthamaArrayCard = [
    {
        "title": "GESUND BLEIBEN",
        "slogan":  <div className="leading-tight mt-1">
        <div>RAUCH VERMEIDEN/MIT DEM</div>
        <div className="">
        RAUCHEN AUFHÖREN
        </div>
    </div>,
        "path": "/images/cigarette.png",
        "custom_width": null

    },
    {
        "title": "AKTIV BLEIBEN",
        "slogan": <div className="leading-tight mt-1">
            <div>KÖRPERLICH AKTIV SEIN </div>
            {/* <div className="">
                AUSÜBEN
            </div> */}
        </div>,
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]",

    },
    {
        "title": "FIT BLEIBEN",
        "slogan": "AUSGEWOGEN ESSEN",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]"

    },
    {
        "title": "BEREIT SEIN",
        "slogan": "ASTHMAANFÄLLE VERHINDERN",
        "path": "/images/Ready.png",
        "custom_width": null
    },
    {
        "title": "VERBUNDEN SEIN",
        "slogan": <div className="leading-tight mt-1">
            <div>DEPRESSION, ANGST UND</div>
            <div className="">
                PANIK ERKENNEN
            </div>
        </div>,
        "path": "/images/Connected.png",
        "custom_width": "w-[100%]"
    }
]

export const AsthamaDetailArrayCard = [
    {
        "title": "GESUND BLEIBEN",
        "slogan": "RAUCH VERMEIDEN/MIT DEM RAUCHEN AUFHÖREN",
        "path": "/images/cigarette.png",
        "custom_width": null,
        "custom_classes": "lg:mt-12",
        "description": <div className=' text-justify leading-tight font-light  '>
            <div >
                <span className="font-medium">Tabakrauch ist ein starker Auslöser von Asthmasymptomen und reizt die Schleimhaut der Atemwege. Passivrauchen kann für eine Person mit Asthma noch schädlicher
                sein. Dies gilt für Erwachsene, aber besonders für Kinder.</span><sup>1</sup>
            </div>

            <div>
                Wenn Sie rauchen, hören Sie auf – für sich selbst und Ihre Kinder. Wenn Ihr Partner/Ihre Partnerin oder andere Familienmitglieder rauchen, helfen Sie ihnen, die Gefahren des Rauchens zu verstehen, und ermutigen Sie sie, aufzuhören. Das Aufhören ist nicht immer einfach. Es gibt viele Programme und Methoden, die Ihnen helfen können, mit dem Rauchen aufzuhören. Bitten Sie Ihre Ärztin/Ihren Arzt, Ihnen zu helfen, die Methode zu finden, die am besten zu Ihnen passt.<sup>2</sup>
            </div>
        </div>,

        "references":
            <ul className='my-5 list-decimal italic'>
                <li>
                    Centers for Disease Control and Prevention. Asthma and Secondhand Smoke. <a className="underline" href='https://www.cdc.gov/tobacco/campaign/tips/diseases/secondhand-smoke-asthma.html' target='_blank' >https://www.cdc.gov/tobacco/campaign/tips/diseases/secondhand-smoke-asthma.html</a> Accessed 5/3/2021
                </li>
                <li>
                    National Heart, Lung, and Blood Institute. So You Have Asthma (PDF) <a className="underline" href='https://www.nhlbi.nih.gov/files/docs/public/lung/SoYouHaveAsthma_PRINT-reduced-filesize.pdf' target='_blank' >https://www.nhlbi.nih.gov/files/docs/public/lung/SoYouHaveAsthma_PRINT-reduced-filesize.pdf</a> Accessed 5/3/2021
                </li>

            </ul>


    },
    {
        "title": "AKTIV BLEIBEN",
        "slogan": "KÖRPERLICH AKTIV SEIN",
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]", //for below line
        "custom_classes": "lg:mt-12",

        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    Lebensqualität ist ein Schlüsselfaktor bei der Betrachtung von Behandlungsinterventionen, da sie eine Bewertung des wahrgenommenen Wohlbefindens der Patientin/des Patienten bietet. <span className="font-medium">Körperliche Aktivität verbessert die Asthmakontrolle, die Lebensqualität, die Lungenfunktionsparameter und die Entzündung</span>. Darüber hinaus trägt körperliche Aktivität zur Verbesserung des subjektiven Wohlbefindens der Patient:innen und der allgemeinen Lebensqualität bei. Daher <span className="font-medium">sollten Menschen mit Asthma ermutigt werden, regelmäßig Sport zu betreiben</span>, um die Asthmaergebnisse zu verbessern, ohne Angst vor einer Verschlimmerung der Symptome.<sup>1</sup>
                </div>
            </div>,
        "references":
            <ul className='my-5 list-decimal italic'>
                <li>
                    <a className="underline" href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8434961/' target='_blank' >https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8434961/</a>
                </li>

            </ul>
    },
    {
        "title": "FIT BLEIBEN",
        "slogan": "AUSGEWOGEN ESSEN",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className="font-medium">Das Körpergewicht spielt eine große Rolle bei Asthma. Übergewicht oder Fettleibigkeit können die Entzündung im Körper erhöhen, was Asthma verschlimmern kann. Eine ausgewogene Ernährung ist ein wichtiger</span> Bestandteil Ihrer allgemeinen Gesundheit und Ihres Asthmabehandlungsplans. Sie kann Ihnen helfen, ein gesundes Körpergewicht zu halten, <span className="font-medium">was Ihre Asthmasymptome verbessern kann</span>. Zudem liefert sie viele <span className="font-medium">Antioxidantien, die dazu beitragen können, Schwellungen und Entzündungen in Ihren Lungen zu reduzieren</span>.<sup>1</sup>
                </div>

            </div>,
        "references":
            <ul className=' mx-5 my-5 list-decimal italic'>
                <li>
                    <a className="underline" href='https://pubmed.ncbi.nlm.nih.gov/31777565/' target='_blank' >https://pubmed.ncbi.nlm.nih.gov/31777565/</a>
                </li>
            </ul>,
        "reference_length": 1


    },
    {
        "title": "BEREIT SEIN",
        "slogan": "ASTHMAANFÄLLE VERHINDERN",
        "path": "/images/Ready.png",
        "custom_width": null,
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <p><span className="font-medium">Der beste Weg, einen Asthmaanfall zu verhindern, ist die Befolgung Ihres Behandlungsplans</span>. Lernen Sie Ihre Auslöser kennen und vermeiden oder reduzieren Sie die Exposition gegenüber diesen.</p>
                    <p>Viele Menschen leben ein gesundes Leben mit Asthma, wenn es gut kontrolliert ist. <span className="font-medium">Mit einem guten Behandlungsplan und der Anleitung Ihrer Ärztin/Ihres Arztes können Sie weiterhin vieles tun, was Ihnen Freude bereitet</span>. Zum Beispiel haben auch viele Profisportler Asthma.<sup>1</sup></p>
                </div>
            </div>,
        "references":
            <ul className='my-5 list-decimal italic'>
                <li>
                    Centers for Disease Control. Asthma. <a className="underline" href='http://www.cdc.gov/asthma/default.htm.' target='_blank' >http://www.cdc.gov/asthma/default.htm.</a>
                </li>
            </ul>
    },
    {
        "title": "VERBUNDEN SEIN",
        "slogan": "DEPRESSION, ANGST UND PANIK ERKENNEN",
        "path": "/images/Connected.png",
        "custom_width": "w-[100%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <p>Wenn Sie auch nur für kurze Zeit mit Asthmasymptomen leben, <span className="font-medium">ist es dennoch wichtig, gezielte Hilfe zu suchen</span>.</p>
                    <p>Sie können Hilfe von Expert:innenen wie Ihrer Ärztin/Ihrem Arzt oder einem Asthmaspezialisten und von anderen Menschen, die Asthma haben, erhalten.</p>
                    <p>Die wiederholten Anfälle von Husten, Engegefühl in der Brust, Keuchen und Atemnot können jeden ängstlich, überwältigt und sogar niedergeschlagen fühlen lassen. <span className="font-medium">Unterstützung zu bekommen, wenn man Asthma hat, ist wichtig</span>. Die Menschen um Sie herum – Familienmitglieder, Freunde, Arbeitskolleg:innen – können alle helfen.</p>
                    <p>Sie können Unterstützung bei Asthma durch Online-Organisationen, Selbsthilfegruppen und durch den Austausch von Erfahrungen mit anderen Menschen mit Asthma finden. Mit anderen zu sprechen, kann helfen, einen Teil des Stresses abzubauen, den Sie möglicherweise empfinden.<sup>1</sup>
                    </p>
                </div>
            </div>,

        "references":
            <ul className=' mx-2 my-5 list-decimal italic'>
                <li>
                    <a className="underline" href='https://www.webmd.com/asthma/exercising-asthma' target='_blank' >https://www.webmd.com/asthma/exercising-asthma</a>
                </li>

            </ul>,
        "reference_length": 1

    }
]