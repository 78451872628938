import React from 'react'

const MainRiskFact = () => {
    return (
        <div className='pb-8 pt-4'>
            <div className='text-primary font-bold pb-4'>
                Quali sono i principali fattori di rischio associati alla BPCO?
            </div>
            <div className='space-y-5 font-light'>
                <div>
                    <span className='font-medium'>Le principali esposizioni ambientali che portano alla BPCO sono il fumo di tabacco</span> e l'inalazione di sostanze e gas tossici derivanti dall'inquinamento atmosferico, domestico ed esterno. Ci sono anche fattori di rischio genetici che possono predisporre alcuni soggetti allo sviluppo della malattia; tra questi possiamo citare <span className='font-medium'>rare mutazioni genetiche che portano alla carenza di α-1 antitripsina</span>.
                    <sup>2,7</sup>
                </div>
                <div>
                <span className='font-medium'>Un altro fattore di rischio legato alla BPCO è la prematurità</span>. Questa è associata ad un basso peso alla nascita, a problemi nutrizionali, ad una maggiore suscettibilità ad infezioni respiratorie e ridotta funzione polmonare. 
                Insieme ai fattori come l'esposizione al fumo di tabacco nella seconda metà della gravidanza, la prematurità può causare una compromissione dello sviluppo alveolare, vascolare e delle vie aeree, portando a una riduzione della maturazione polmonare post-natale, <span className='font-medium'>in particolare nei neonati nati prima delle 28 settimane di età gestazionale</span>. Queste condizioni aumentano la possibilità di sviluppare la BPCO negli anni.<sup>7</sup>

                </div>
            </div>
        </div>
    )
}

export default MainRiskFact