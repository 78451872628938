import React from 'react'
import { FiExternalLink } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const About = () => {
    const language = useSelector(state => state.language.lang);

    return (
        <div className='relative px-5 sm:px-12 pt-3 pb-24 sm:pt-10 sm:pb-[70px] text-justify text-black'>
            <h2 className='text-xl uppercase sm:text-3xl lg:text-4xl text-primary font-bold  text-center mb-3 sm:mb-8'>
            A PROPOS DU GROUPE CHIESI
            </h2>
            <div className='mb-5 sm:mb-6 '>
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                CHIESI est un groupe familial pharmaceutique italien proposant des solutions thérapeutiques innovantes aux patients dans ses trois domaines d'expertise : AIR (produits et solutions pour les maladies 
                respiratoires du nouveau-né à l'adulte), RARE (traitements pour les maladies rares et ultra-rares), 
                CARE (produits et services en transplantation rénale et hépatique) et prévention (micronutrition).
                </p>
            </div>
            <div className="mb-5 sm:mb-6">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                Regroupant près de 6 500 collaborateurs dans le monde, CHIESI est présent en France sur l'ensemble de la chaîne de valeur ajoutée – R&D, 
                production, distribution et mise à disposition auprès des professionnels de santé et des patients - avec un chiffre d'affaires atteignant près de 175 M€ en 2022.
                </p>


            </div>
            <div className="mb-5 sm:mb-6">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                Certifié B Corp, CHIESI s'engage dans l'amélioration de la qualité de vie des patients, de manière responsable et durable envers la Société et l'environnement. 
                Depuis avril 2021, CHIESI France est le premier laboratoire pharmaceutique Société à Mission.
                </p>
            </div>
            <div className="mb-5 sm:mb-8">
                <p className="text-base sm:text-lg lg:text-xl leading-tight font-light">
                    Pour davantage d’informations  <a href="https://www.chiesi.fr" className='underline'>www.chiesi.fr</a>
                </p>
            </div>
            <div className='mt-8'>
                <Link target="_blank" to={`/copd/${language.value ? language.value : "en"}/cookie-policy`} className="mt-5 flex items-center space-x-3 text-base sm:text-lg lg:text-xl font-bold">
                    <p>
                        POLITIQUE EN MATIÈRE DE COOKIES
                    </p>
                    <FiExternalLink />
                </Link>
            </div>

        </div>
    )
}

export default About