export const ArrayCard = [
    {
        "title": "BE HEALTHY",
        "slogan": "AVOID SMOKE/QUIT SMOKING",
        "path": "/images/cigarette.png",
        "custom_width": null

    },
    {
        "title": "BE ACTIVE",
        "slogan": "DO PHYSICAL ACTIVITY",
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]",

    },
    {
        "title": "BE FIT",
        "slogan": "EAT BALANCED / BALANCED DIET",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]"

    },
    {
        "title": "BE FREE TO MOVE",
        "slogan": "KEEP EXPLORING",
        "path": "/images/aeroplan.png",
        "custom_width": null
    },
    {
        "title": "BE CONNECTED",
        "slogan": <div className="leading-tight mt-1">
            <div>RECOGNIZE DEPRESSION, </div>
            <div className="">
                ANXIETY, PANIC
            </div>
        </div>,
        "path": "/images/Connected.png",
        "custom_width": "w-[100%]"
    }
]


export const DetailArrayCard = [
    {
        "title": "BE HEALTHY",
        "slogan": "AVOID SMOKE/QUIT SMOKING",
        "path": "/images/cigarette.png",
        "custom_width": null,
        "custom_classes": "lg:mt-12",
        "description": <div className='space-y-5 text-justify leading-tight font-light  '>
            <div>
                It is widely recognized that smoking, including the use of electronic cigarettes, <span className='font-medium'>
                    is detrimental to both your own health and the well-being of those around you
                </span>. However, for individuals with respiratory diseases,<span className='font-medium'> smoking can be particularly
                    harmful as it is a major contributing factor to the development of COPD</span>. Research has shown that <span className='font-medium'>quitting smoking can
                        significantly decrease the risk of hospitalization and mortality among COPD patients</span>.<sup>1</sup>
            </div>

            <div>
                <span className='font-medium'>If you feel that you need assistance in quitting, there are various avenues you can explore</span>. Three types of counseling
                have proven to be  <span className='font-medium'>particularly effective: practical counseling</span>, receiving <span className='font-medium'>support from family and</span> <span className='font-medium'>friends</span> as part of the
                treatment process, and seeking <span className='font-medium'>social support outside of formal treatment settings</span>.<sup>2</sup>
            </div>
        </div>,
        "references":
            <ul className='list-decimal italic'>
                <li>
                    Global Allergy and Airways Patients Platform: <span className='underline'><a href='https://it.gaapp.org/diseases/copd/copd-and-smoking/' target='_blank' >https://it.gaapp.org/diseases/copd/copd-and-smoking/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 111
                </li>
            </ul>


    },
    {
        "title": "BE ACTIVE",
        "slogan": "DO PHYSICAL ACTIVITY",
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]", //for below line
        "custom_classes": "lg:mt-12",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    It is widely known that even a gentle form of <span className='font-medium'>physical activity can greatly benefit people of all ages and conditions</span>.<sup>1 </sup>
                    You don’t have to push yourself to the limit every day to take care of your respiratory system. Research shows that individuals
                    with COPD often engage in less physical activity<sup>2</sup>.
                    <span className='font-medium'> It is crucial to stay active for overall well-being</span>. Therefore, it is
                    important to <span className='font-medium'>stay active to maintain good health</span>. Consider incorporating a pleasant <span className='font-medium'>daily walk into your routine</span>:
                    did you know that <span className='font-medium'>just 30 minutes of brisk walking can enhance breathing quality</span><sup>3 </sup>
                    in people with COPD?
                </div>
                <div>
                    <span className='font-medium'>Opt for stairs</span> over the elevator, <span className='font-medium'>do some light gardening</span>, or <span className='font-medium'> enjoy a quick swim </span> whenever possible. When engaging
                    in physical activity, <span className='font-medium'>it is crucial to do it correctly, even if it is a light activity</span>. Start with  <span className='font-medium'>stretching or warming up</span>,
                     <span className='font-medium'> stay hydrated</span>, take your time, and <span className='font-medium'>ensure your medications are readily available and check with your doctor for the best solution for your overall condition</span>.
                </div>
            </div>,
        "references":
            <ul className='list-decimal italic'>
                <li>
                    European Lung Foundation: <span className='underline'><a href='https://europeanlung.org/it/information-hub/factsheets/vivere-una-vita-attiva-con-la-bpco/' target='_blank' >https://europeanlung.org/it/information-hub/factsheets/vivere-una-vita-attiva-con-la-bpco/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 122
                </li>
                <li>
                    Better Health Channel: <span className='underline'><a href='https://www.betterhealth.vic.gov.au/health/healthyliving/walking-for-good-health' target='_blank' >https://www.betterhealth.vic.gov.au/health/healthyliving/walking-for-good-health</a></span>
                </li>
            </ul>
    },
    {
        "title": "BE FIT",
        "slogan": "EAT BALANCED / BALANCED DIET",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className='font-medium'>Your diet plays a crucial role in supporting healthy breathing</span>. Body weight can significantly impact respiratory function
                    and exacerbate COPD symptoms. Additionally, it is important to note that many COPD patients experience symptoms of
                    protein-energy malnutrition. <span className='font-medium'>The solution lies in maintaining a well-balanced diet.</span>
                </div>
                <div>
                    This includes <span className='font-medium'>limiting salt intake</span>, <span className='font-medium'>reducing the consumption of fats</span>, <span className='font-medium'>consuming adequate amounts of fruits for essential vitamins and minerals</span>, and ensuring a <span className='font-medium'>good source of protein
                        is included in your meals</span>. This approach will contribute to the upkeep of robust respiratory muscles.<sup>1</sup>


                </div>
            </div>,

        "references":
            <ul className='ml-4 list-decimal italic'>
                <li>
                    American Lung Association: <span className='underline'><a href='https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/nutrition' target='_blank' >https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/nutrition/</a></span>
                </li>
            </ul>,
        "reference_length": 1


    },
    {
        "title": "BE FREE TO MOVE",
        "slogan": "KEEP EXPLORING",
        "path": "/images/aeroplan.png",
        "custom_width": null,
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className='font-medium'>Having a chronic respiratory condition should not hinder your ability to travel<sup className='font-light'>1</sup>, visit loved ones, or enjoy holidays. </span>
                    However, it is crucial to do some research beforehand. Always <span className='font-medium'>consider the climate of your destination</span>, taking into account
                    <span className='font-medium'> the temperature</span> (as COPD symptoms can worsen in hot environments), <span className='font-medium'>altitude</span> (higher altitudes have lower oxygen
                    levels, so consult your doctor before traveling to mountainous areas), and <span className='font-medium'>air quality in terms of pollution and allergens</span><sup>2</sup>.
                
                    It is worth noting that even if your resting oxygenation is normal at sea level, you may still experience severe hypoxemia
                    when traveling by air<sup>3</sup>
                    . Therefore, <span className='font-medium'>if you plan to fly</span>, it is advisable to <span className='font-medium'>consult your doctor</span> <span className='font-medium'> and inform the airline</span> about your
                    condition when purchasing your ticket. They can ensure that you <span className='font-medium'>have a safe and comfortable flight</span> with appropriate
                    accommodations.

                </div>
            </div>,
        "references":
            <ul className='list-decimal italic'>
                <li>
                    COPD Foundation: <span className='underline'><a href='https://www.copdfoundation.org/Learn-More/I-am-a-Person-with-COPD/Traveling-with-COPD.aspx' target='_blank' >https://www.copdfoundation.org/Learn-More/I-am-a-Person-with-COPD/Traveling-with-COPD.aspx</a></span>
                </li>
                <li>
                    European Lung Foundation: <span className='underline'><a href='https://europeanlung.org/en/information-hub/factsheets/living-an-active-life-with-copd/' target='_blank' >https://europeanlung.org/en/information-hub/factsheets/living-an-active-life-with-copd/</a></span>
                </li>
                <li>
                    GOLD Report 2023 – pag. 81
                </li>
            </ul>
    },
    {
        "title": "BE CONNECTED",
        "slogan": "RECOGNIZE DEPRESSION, ANXIETY, PANIC",
        "path": "/images/Connected.png",
        "custom_width": "w-[100%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className='font-medium'>Depression </span>and <span className='font-medium'>anxiety are more common</span> among individuals diagnosed with COPD compared to the general population.
                    These challenges can be difficult to recognize, accept, and treat, especially at the outset. <span className='font-medium'>The first crucial step is
                        acknowledging them</span>. If you believe that your COPD is impacting your mental well-being, <span className='font-medium'>start a conversation with your
                            healthcare professionals</span>. Remember that <span className='font-medium'>mental health is just as vital as physical health</span>, and your doctor can help you
                    identify any issues and develop a treatment plan if necessary.

                </div>
                <div>
                    <span className='font-medium'>It's essential to recognize that you are not alone</span>. Seek support from your loved ones for support and don't hesitate to
                    show your emotions. <span className='font-medium'>Connecting with other COPD patients can also be beneficial</span>, as you can share experiences and learn
                    from one another.  <span className='font-medium'>Explore local patient advocacy groups for more information and support</span>.<sup>1</sup>
                </div>
            </div>,


        "references":
            <ul className='ml-4 list-decimal italic'>
                <li>
                    American Lung Association: <span className='underline'><a href='https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/coping-with-emotions/' target='_blank' >https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/living-with-copd/coping-with-emotions</a></span>
                </li>

            </ul>,
        "reference_length": 1

    }
]
export const AsthamaArrayCard = [
    {
        "title": "BE HEALTHY",
        "slogan": "AVOID SMOKE/QUIT SMOKING",
        "path": "/images/cigarette.png",
        "custom_width": null

    },
    {
        "title": "BE ACTIVE",
        "slogan": "DO PHYSICAL ACTIVITY",
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]",

    },
    {
        "title": "BE FIT",
        "slogan": "EAT BALANCED",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]"

    },
    {
        "title": "BE READY",
        "slogan": "PREVENT ASTHMA ATTACKS",
        "path": "/images/Ready.png",
        "custom_width": null
    },
    {
        "title": "BE CONNECTED",
        "slogan": <div className="leading-tight mt-1">
            <div>RECOGNIZE DEPRESSION, </div>
            <div className="">
                ANXIETY, PANIC
            </div>
        </div>,
        "path": "/images/Connected.png",
        "custom_width": "w-[100%]"
    }
]

export const AsthamaDetailArrayCard = [
    {
        "title": "BE HEALTHY",
        "slogan": "AVOID SMOKE/QUIT SMOKING",
        "path": "/images/cigarette.png",
        "custom_width": null,
        "custom_classes": "lg:mt-12",
        "description": <div className=' text-justify leading-tight font-light  '>
            <div className="font-medium">
                Tobacco smoke is a powerful trigger for asthma symptoms, irritating the lining of the airways. Second-hand smoke can be even more harmful to a
                person with asthma. This is true for adults, but especially for children.<sup>1</sup>
            </div>

            <div>
                If you smoke, quit for yourself and your children. If your partner or other family members smoke, help them understand the dangers of smoking and
                encourage them to quit. Quitting is not always easy. There are many programs and methods to help you quit smoking. Ask your doctor to help you
                find the method that suits you best.<sup>2</sup>
            </div>
        </div>,

        "references":
            <ul className='my-5 list-decimal italic'>
                <li>
                    Centers for Disease Control and Prevention. Asthma and Secondhand Smoke. <a className="underline" href='https://www.cdc.gov/tobacco/campaign/tips/diseases/secondhand-smoke-asthma.html' target='_blank' >https://www.cdc.gov/tobacco/campaign/tips/diseases/secondhand-smoke-asthma.html</a> Accessed 5/3/2021.
                </li>
                <li>
                    National Heart, Lung, and Blood Institute. So You Have Asthma (PDF) <a className="underline" href='https://www.nhlbi.nih.gov/files/docs/public/lung/SoYouHaveAsthma_PRINT-reduced-filesize.pdf' target='_blank' >https://www.nhlbi.nih.gov/files/docs/public/lung/SoYouHaveAsthma_PRINT-reduced-filesize.pdf</a> Accessed 5 /3/2021.
                </li>

            </ul>


    },
    {
        "title": "BE ACTIVE",
        "slogan": "DO PHYSICAL ACTIVITY",
        "path": "/images/weight_liffter.png",
        "custom_width": "w-[100%]", //for below line
        "custom_classes": "lg:mt-12",

        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    Quality of life is a key factor when considering treatment interventions, as it provides an assessment of the patient’s perceived well-being.
                    <span className="font-medium"> Physical activity improves asthma control, quality of life, lung function parameters, and inflammatory serologies</span>. Furthermore, physical activity enhances patients’ perceived well-being and overall quality of life. Therefore, people with asthma should be
                    <span className="font-medium"> encouraged to engage in regular exercise training</span> to improve asthma outcomes, without fear of exacerbating symptoms.<sup>1</sup>
                </div>
            </div>,
        "references":
            <ul className='my-5 list-decimal italic'>
                <li>
                    <a className="underline" href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8434961/' target='_blank' >https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8434961/</a>
                </li>

            </ul>
    },
    {
        "title": "BE FIT",
        "slogan": "EAT BALANCED",
        "path": "/images/spoon.png",
        "custom_width": "w-[130%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <span className="font-medium">Body weight plays a significant role in asthma</span>. Being overweight or obese <span className="font-medium">can increase inflammation in your body, worsening asthma symptoms</span>.
                   <span className="font-medium"> A nutritious diet is crucial</span> to your overall health and asthma treatment plan. It can help you maintain a healthy body weight, thereby <span className="font-medium"> improving
                    your asthma symptoms</span>. Additionally, it provides plenty of <span className="font-medium">antioxidant nutrients that can help reduce inflammation in your lungs</span>.<sup>1</sup>
                </div>

            </div>,
        "references":
            <ul className=' mx-5 my-5 list-decimal italic'>
                <li>
                    <a className="underline" href='https://pubmed.ncbi.nlm.nih.gov/31777565/' target='_blank' >https://pubmed.ncbi.nlm.nih.gov/31777565/</a>
                </li>
            </ul>,
        "reference_length": 1


    },
    {
        "title": "BE READY",
        "slogan": "PREVENT ASTHMA ATTACKS",
        "path": "/images/Ready.png",
        "custom_width": null,
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <p className="pb-5"><span className="font-medium">The best way to prevent an asthma attack is to adhere to your treatment plan</span>. Identify your triggers and avoid or minimize exposure to them.</p>
                    <p>Many people lead healthy lives with well-managed asthma. <span className="font-medium">With a good treatment plan and guidance from your doctor, you can continue to enjoy
                    much of what you love</span>. For instance, many professional athletes have asthma.<sup>1</sup></p>
                </div>
            </div>,
        "references":
            <ul className='my-5 list-decimal italic'>
                <li>
                    Centers for Disease Control. Asthma. <a className="underline" href='http://www.cdc.gov/asthma/default.htm.' target='_blank' >http://www.cdc.gov/asthma/default.htm.</a>
                </li>
            </ul>
    },
    {
        "title": "BE CONNECTED",
        "slogan": "RECOGNIZE DEPRESSION, ANXIETY, PANIC",
        "path": "/images/Connected.png",
        "custom_width": "w-[100%]",
        "description":
            <div className='space-y-5 text-justify leading-tight font-light  '>
                <div>
                    <p>If you’ve been experiencing asthma symptoms even for a short period, <span className="font-medium">seeking specialized help is crucial</span>. Get assistance from professionals such
                    as your doctor or an asthma specialist, as well as from others who have asthma.</p>
                    <p>Repeated bouts of coughing, congestion, wheezing, and gasping for breath can cause anyone to feel anxious, overwhelmed, and even defeated.</p>
                    <p><span className="font-medium">Getting support when you have asthma is important</span>. The people around you -- family members, friends, co-workers -- can all help.</p>
                    <p>You can find support with asthma through online organizations, support groups in your community and by sharing experiences with others who
                    have asthma. Talking to others can help ease some of the stress you might feel.<sup>1</sup></p>
                    
                </div>
            </div>,

        "references":
            <ul className=' mx-2 my-5 list-decimal italic'>
                <li>
                    <a className="underline" href='https://www.webmd.com/asthma/exercising-asthma' target='_blank' >https://www.webmd.com/asthma/exercising-asthma</a>
                </li>

            </ul>,
        "reference_length": 1

    }
]