import React from 'react'
import Card from '../../Card'
import { AnimatePresence, motion } from "framer-motion";
import DetailCard from '../../Card/DetailCard';
import { ArrayCard, DetailArrayCard } from '../../Card/ArrayCard'

const HowToLive = ({ active, setActive }) => {
    const handleClick = (item, index) => {
        let x = {
            ...item,
            activeIndex: index
        }
        setActive(x)
    }


    return (

        <div className='space-y-5 font-light pt-4'>
            <div className=''>
            <span className='font-medium'>Mit COPD zu leben bedeutet nicht einfach, die Krankheit zu ertragen; es geht darum, positive Veränderungen zu begrüßen, die einen echten Unterschied machen können</span>. Durch das Übernehmen gesunder Gewohnheiten und die Priorisierung Ihres Wohlbefindens können <span className='font-medium'>Sie die Auswirkungen von COPD auf Ihr tägliches Leben effektiv in die Hand nehmen</span>. Sich mit genauen Informationen auszustatten und sowohl Körper als auch Geist durch einfache, bewusste Handlungen zu pflegen, ist der Schlüssel dazu. Um Ihnen zu helfen, das Beste aus jedem Tag zu machen und die Herausforderungen von COPD zu  minimieren, <span className='font-medium'>finden Sie hier einige praktische Tipps, Atemzug für Atemzug</span>.

            </div>
            {
                active === null ?
                    <div className='font-medium'>
                        FÜR WEITERE INFORMATIONEN UND RATSCHLÄGE ZU COPD, WENDEN SIE SICH BITTE AN IHREN ARZT, AN IHRE ÄRZTIN.
                    </div>
                    :
                    ""
            }
            <AnimatePresence mode='sync' >
                {
                    active &&
                    <motion.div
                        key={"detail-poup"}
                        initial={{ 
                            height: "0",
                            opacity: 0
                        }}
                        animate={{ 
                            height: "auto",
                            opacity: 1
                        }}
                        exit={{ 
                            height: "0",
                            opacity: 0
                        }}
                        className='overflow-hidden'
                        transition={{
                            type:"tween",
                            duration: 0.3
                        }}
                    >
                        <DetailCard
                            item={DetailArrayCard[active.activeIndex]}
                            setActive={setActive}
                        />
                    </motion.div>

                }
                 {
                active === null &&
                    <motion.div
                        key={"grid"}
                        initial={{ 
                            height: "0",
                            opacity: 0
                        }}
                        animate={{ 
                            height: "auto",
                            opacity: 1
                        }}
                        exit={{ 
                            height: "0",
                            opacity: 0
                        }}
                        transition={{
                            type:"tween",
                            duration: 0.3
                        }}
                        className='origin-bottom overflow-hidden '
                        >
                            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5  pt-8 pb-12'>

                            <div className='h-[210px] w-[245px] md:w-[270px] pl-5 cursor-pointer text-primary '>
                                <h1 className='font-medium mb-3 text-3xl text-left	'>Ein Leitfaden für ein erfülltes Leben</h1>
                                <div>
                                    <img src='/images/row_guide.png' className='w-24' />
                                </div>
                            </div>
                                {

                                    ArrayCard.map((item, index) => (
                                        <div onClick={() => handleClick(item, index)}>
                                            <Card
                                                key={`item${index}`}
                                                index={index}
                                                item={item}
                                                active={active}
                                                setActive={setActive}
                                            />
                                        </div>

                                    ))
                                }
                            </div>

                    </motion.div>
            }
            </AnimatePresence>

           
        </div>
    )
}

export default HowToLive