import React from 'react'

const WhatIsCOPD = () => {
    return (
        <div className='pt-4'>
            <p>
                D'après les dernières estimations, la <span  className='font-medium'>BPCO touche plus de 300 millions de personnes dans le monde</span> et les prévisions indiquent que la prévalence et les problèmes liés à la BPCO augmenteront au cours des prochaines décennies, en raison d'une exposition continue aux <span className='font-medium'>facteurs de risque de la BPCO et du vieillissement de la population mondiale</span>.
                <sup>1,2</sup>
            </p>
            <p>
                On a également observé une augmentation de la fréquence de la maladie chez les femmes, due en partie à l'augmentation du tabagisme féminin dans le monde entier et à l'exposition aux combustibles de la biomasse<sup>3</sup>.
            </p>
            <p>
                D’après les dernières estimations de l'OMS (Organisation mondiale de la santé), la BPCO est la troisième cause de décès dans le monde,
                avec 3,23 millions de décès en 2019.<sup>1,2,4</sup>
            </p>
            <p>
            Les erreurs de diagnostic et les sous-diagnostics sont fréquents, conduisant les patients à ne pas bénéficier de traitement ou à recevoir un traitement inadapté. Un diagnostic approprié et précoce de la BPCO peut avoir un impact très important sur la santé publique.<sup>2</sup>
            </p>
            <div className='pt-5'>
                <span className='font-bold'>APERÇU SUR LA BPCO</span>
                <div className='pt-3'>
                    <span className='text-primary font-bold'>BPCO signifie BronchoPneumopathie Chronique Obstructive</span>, ce qui veut dire<sup>5</sup> :

                    <ul className='list-disc py-2 px-9'>
                        <li><span className='text-primary font-bold'>B</span>ronchoPneumopathie : il s'agit d'<span className='font-medium'>une maladie pulmonaire</span> inflammatoire qui affecte les bronches</li>
                        <li><span className='text-primary font-bold'>C</span>hronique : il s'agit d'une affection de longue durée</li>
                        <li><span className='text-primary font-bold'>O</span>bstructive : les voies respiratoires sont devenues plus étroites, bloquant légèrement, ou obstruant partiellement, le flux d'air pendant l'expiration</li>
                    </ul>

                    <div className='py-8 space-y-4 text-light'>
                        <div className=''>
                        Le terme BPCO est utilisé pour décrire <strong className='font-medium'>une maladie pulmonaire</strong> caractérisée par un rétrécissement progressif et une <span className='font-medium'>obstruction permanente des voies aériennes et des poumons.</span> Cela se produit lorsque le tissu pulmonaire est endommagé, entrainant une gêne respiratoire. 
                        La paroi des voies respiratoires peut être gonflée et enflammée, et des glaires peuvent également obstruer ces dernières. 
                        Tous ces éléments rendent plus <span className='font-medium'>difficile l'entrée et la sortie de l'air pendant la respiration, et les poumons perdent en partie leur capacité à absorber l'oxygène</span> et à se débarrasser du dioxyde de carbone.<sup>5</sup>
                        </div>
                        <div>
                        Les principaux symptômes de la BPCO sont la <span className='font-medium'>dyspnée</span> (sensation d’une respiration difficile), <span className='font-medium'>la toux</span> (avec ou sans glaires), <span className='font-medium'>les sifflements et une oppression thoracique</span>. 
                        Ces symptômes peuvent avoir pour conséquences la limitation des activités quotidiennes chez ces personnes. 
                        Les personnes atteintes de <strong className='font-medium'>BPCO peuvent être confrontées à des événements aigus caractérisés par une augmentation des symptômes respiratoires,</strong> appelés exacerbations, qui agissent sur leur état de santé ainsi que sur le diagnostic et <span className='font-medium'>nécessitent que soient adoptées des mesures préventives et thérapeutiques spécifiques.<sup>2 </sup></span>
                         Les patients atteints de <span className='font-medium'>BPCO présentent souvent d'autres maladies concomitantes</span> qui influencent également leur état santé, le diagnostic, <span className='font-medium'>et nécessitent un traitement spécifique</span>.
                        </div>
                        <div>
                        Ces <span className='font-medium'>maladies concomitantes peuvent reproduire et/ou aggraver une exacerbation</span>. Les maladies chroniques les plus fréquemment associées à la BPCO sont les troubles cardiovasculaires et respiratoires, des maladies des articulations ou des muscles, les maladies psychiatriques, les maladies rénales chroniques, le reflux gastro-œsophagien, l'anémie et le cancer.
                        </div>
                        <div>
                        <span className='font-medium'>Les maladies concomitantes arrivent plus tôt dans la vie des personnes atteintes de BPCO</span> et restent souvent non ou mal diagnostiquées, et non ou mal prises en charge. 
                        Les maladies couramment observées chez les patients souffrant de BPCO ne surviennent pas de manière aléatoire. Elles découlent de syndromes, qui sont des ensembles de maladies ayant des facteurs de risque et des interactions biologiques similaires. 
                        Ces syndromes aggravent le diagnostic et l'impact de la maladie sur les patients et la société.<sup>6</sup>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WhatIsCOPD