// import { store } from "react-notifications-component";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import { ACCESS_TOKEN, ENGLISH_LANGUAGE, initialPopupState, LANGUAGES, LANG_ACCESS, LANG_ACCESS_SELECTED } from "./constants";

export const getAuthUser = () => {
    let token = sessionStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).user;
    } else {
        return false;
    }
}

export const getToken = () => {
    let token = sessionStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).access_token;
    } else {
        return false;
    }
}

export const setToken = (access_token) => {
    sessionStorage.setItem(ACCESS_TOKEN, JSON.stringify(access_token));
}

export const removeToken = () => {
    sessionStorage.removeItem(ACCESS_TOKEN);
}

export const getLang = () => {
    const found = sessionStorage.getItem(LANG_ACCESS);
    if(found){
        let parsed = JSON.parse(found)
        let f_lang = LANGUAGES.find(item => item.value === parsed.value )
        if(f_lang){
            return f_lang
        } else{
            return ENGLISH_LANGUAGE
        }
    } else{
        return ENGLISH_LANGUAGE
    }
}
export const setLang = (lang) => {
    sessionStorage.setItem(LANG_ACCESS, JSON.stringify(lang));
}

export const getLangSelected = () => {
    return sessionStorage.getItem(LANG_ACCESS_SELECTED)
}
export const setLangSelected = () => {
    return sessionStorage.setItem(LANG_ACCESS_SELECTED, 1)
}

export const removeLangSelected = () => {
    return sessionStorage.removeItem(LANG_ACCESS_SELECTED)
}

export const success_alert = (message, title) => {
    let new_msg = "";
    if ((message[0] === '"' || message[0] === "'") && (message[message.length - 1] === '"' || message[message.length - 1] === "'")) {
        new_msg = message.substr(1, message.length - 2);
    } else {
        new_msg = message
    }
    // store.addNotification({
    //     title: title ? title : "",
    //     message: new_msg,
    //     className: "noti",
    //     type: "success",
    //     insert: "bottom",
    //     container: "bottom-right",
    //     animationIn: ["animate__animated", "animate__fadeInUp"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     dismiss: {
    //         duration: 7000,
    //         onScreen: true,
    //         showIcon: true
    //     }
    // })
}

export const error_alert = (message, title) => {
    let new_msg = "";
    if ((message[0] === '"' || message[0] === "'") && (message[message.length - 1] === '"' || message[message.length - 1] === "'")) {
        new_msg = message.substr(1, message.length - 2);
    } else {
        new_msg = message
    }
    // store.addNotification({
    //     title: title ? title : "",
    //     message: new_msg,
    //     className: "noti",
    //     type: "danger",
    //     insert: "bottom",
    //     container: "bottom-right",
    //     animationIn: ["animate__animated", "animate__slideInRight"],
    //     animationOut: ["animate__animated", "animate__slideOutBottom"],
    //     dismiss: {
    //         duration: 7000,
    //         onScreen: true,
    //         showIcon: true
    //     }
    // })
}

export const warning_alert = (message, title) => {
    // store.addNotification({
    //     title: title ? title : "",
    //     message: message,
    //     type: "warning",
    //     insert: "bottom",
    //     container: "bottom-right",
    //     animationIn: ["animate__animated", "animate__fadeIn"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     dismiss: {
    //         duration: 7000,
    //         onScreen: true,
    //         showIcon: true
    //     }
    // })
}

export const b64toBlob = (dataURI) => {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function stringNotEmpty() {
    return yup.mixed().test({
        name: 'stringNotEmpty',
        exclusive: false,
        message: <FormattedMessage id="required" defaultMessage="Required" />,
        test: function (value) {
            if (value !== undefined && value !== false) {
                return value.trim() !== "";
            } else if (value === undefined) {
                return false
            }
        },
    });
}
export function stringNotEmptyWysiyug() {
    return yup.mixed().test({
        name: 'stringNotEmptyWysiyug',
        exclusive: false,
        message: <FormattedMessage id="required" defaultMessage="Required" />,
        test: function (value) {
            if (value !== undefined && value !== false) {
                return value.trim() !== "<p></p>";
            } else if (value === undefined) {
                return false
            }
        },
    });
}

export const selectStyles = error => (
    {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? "#fff" : "#fff",
            borderRadius: "0.375rem",
            border: state.isDisabled ? "2px solid #e4e4e4" : state.isFocused ? "2px solid #40e0d0" :  state.isSelected ? "2px solid #ef4444" : state.hasValue ? "2px solid #40e0d0": error ? "2px solid #ef4444": "2px solid #cbd5e1",
            boxShadow: state.isFocused ? "0px 0px 6px #40e0d0" : "none",
            "&:hover": {
                border: "2px solid #40e0d0",
                boxShadow: "0px 0px 6px #40e0d0"
            },
            fontSize: "1rem",
            "@media (max-width:1024px)": {
                fontSize: "0.875rem"
            }
            
        }),
        option: (provided, state) => {
            return ({
                ...provided,
                background: state.isSelected ? "#40e0d0" : "white",
                color: state.isSelected ? "#000" : "#500",
                "&:hover": {
                    "background": "#40e0d0",
                    color: "#500"
                },
                fontSize: "1rem",
                "@media (max-width:1024px)": {
                    fontSize: "0.875rem"
                }
            })
        },
        
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
    
            return { ...provided, opacity, transition };
        }
    }
)
export const selectStylesFilter = error => (
    {
        
        control: (provided, state) => ({
            ...provided,
            height: "12px",
            fontSize: "12px",
            backgroundColor: state.isDisabled ? "#fff" : "#fff",
            borderRadius: "0.375rem",
            border: state.isDisabled ? "2px solid #e4e4e4" : state.isFocused ? "2px solid #40e0d0" :  state.isSelected ? "2px solid #ef4444" : state.hasValue ? "2px solid #40e0d0": error ? "2px solid #ef4444": "2px solid #cbd5e1",
            boxShadow: state.isFocused ? "0px 0px 6px #40e0d0" : "none",
            "&:hover": {
                border: "2px solid #40e0d0",
                boxShadow: "0px 0px 6px #40e0d0"
            },
            
        }),
        
        option: (provided, state) => {
            return ({
                ...provided,
                background: state.isSelected ? "#40e0d0" : "white",
                color: state.isSelected ? "#000" : "#500",
                fontSize: "12px",
                "&:hover": {
                    "background": "#40e0d0",
                    color: "#500"
                },
            })
        },
        
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
    
            return { ...provided, opacity, transition, fontSize: "12px" };
        }
    }
)


export const hidePopup = (setState) => {
    setState(initialPopupState)
}

export const truncateText = (text) => text.length > 50 ? text.substr(0,40) + "..." : text

export const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const calculateHeight = (width, originalWidth, originalHeight) => {
    return (originalHeight / originalWidth) * width
}

export function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {

    if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.videoWidth,
        ih = img.videoHeight,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;

    // decide which gap to fill    
    if (nw < w) ar = w / nw;                             
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
}


export const removeQuotes = (message) => {
    let new_msg = "";
    if ((message[0] === '"' || message[0] === "'") && (message[message.length - 1] === '"' || message[message.length - 1] === "'")) {
        new_msg = message.substr(1, message.length - 2);
    } else {
        new_msg = message
    }
    return new_msg
}

export const getPercentage = (percent, total) => {
    return Math.floor((percent/100)*total)
}

export const returnFile = (canvas) => {
    return canvas.toBlob((blob) => {
        let file = new File([blob], "fileName.jpg", { type: "image/jpeg" });
      }, 'image/jpeg');
}

export function iOSversion() {
    let ver = false;
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      ver = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
    if(ver && ver[0] === 16){
        return true;
    } else{
        return false;
    }
  }

   
export const handleScroll = (offset) => {
    window.scrollTo({
        top: offset,
        behavior: 'smooth'
    });
}