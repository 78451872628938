import React from 'react'

const MainRiskFact = () => {
    return (
        <div className='pb-8 pt-4'>
            <div className='text-primary font-bold pb-4'>
                Quels sont les principaux facteurs de risque associés à la BPCO ?
            </div>
            <div className='space-y-5 font-light'>
                <div>
                <span className='font-medium'>Les principales conditions d’exposition environnementale à l'origine de la BPCO sont le tabagisme</span> et l'inhalation de particules et de gaz toxiques provenant de la pollution de l'air interne et externe. Il existe également des facteurs de risque génétiques susceptibles de prédisposer certains sujets à développer la maladie.<sup>2,7</sup>
                </div>
                <div>
                <span className=' font-medium'>Un autre aspect important concerne la BPCO liée à des événements survenus au début de la vie, comme la prématurité</span>. La prématurité s’accompagne d’un faible poids à la naissance, de problèmes nutritionnels, d’une vulnérabilité aux infections respiratoires et d’une mauvaise fonction pulmonaire au début de la vie. 
                Associée à des agressions telles que l'exposition à la fumée de tabac au cours de la seconde moitié de la grossesse, la prématurité peut causer des problèmes dans le développement des poumons du bébé, <span className='font-medium'>surtout si le bébé naît avant 28 semaines. Ces bébés auront des poumons moins matures après la naissance, 
                ce qui les rend plus susceptibles de développer une BPCO plus tard dans leur vie</span>
                .<sup>7</sup>
                </div>
            </div>
        </div>
    )
}

export default MainRiskFact