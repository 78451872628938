import React from 'react'

const AreYouCareGiver = (mainTab) => {
    const tab = mainTab.mainTab
    return (
        <div className='mt-8 pb-8 px-5 sm:px-12 leading-tight font-light bg-primary text-[19px]  text-white '>



            {tab == "COPD" ? <div className=' -mt-1 pb-3 text-justify '>
                <div className='flex  items-center space-x-5'>
                    <img src='/images/peoples.png' className='w-32' />
                    <div>
                        <h1 className='text-base sm:text-lg lg:text-xl font-bold text-md'> SIND SIE EINE ANGEHÖRIGE/EIN ANGEHÖRIGER?</h1>
                        <p className='font-light text-[14px] sm:text-[19px]'>Einfache Dinge, die Sie tun können</p>
                    </div>

                </div>
                <div className='-mt-4'>
                    <hr className=' bg-white  text-white' style={{ height: '1px' }} />
                </div>
                <div className='mt-8'>
                    <span className='font-medium'>Es ist keine einfache Aufgabe, sich um eine Person mit COPD zu kümmern, insbesondere wenn es jemand ist, den Sie lieben</span>. Ihr Leben wird sich ändern, möglicherweise auch Ihre Gewohnheiten und Ihre Routine, und dies könnte Sie nervös, vielleicht sogar depressiv oder ängstlich machen.
                </div>
                <div className='mt-4'>
                    Die Lösung, um besser für jemanden mit einer Erkrankung zu sorgen, könnte darin liegen, <span className='font-medium'>sich gut um sich selbst zu kümmern wann immer es möglich ist. Nehmen Sie sich Zeit für sich selbst, laden Sie Ihre inneren Batterien auf</span>. Wenn Sie sich gestresst und überarbeitet fühlen, <span className='font-medium'>bitten Sie um Hilfe</span>, beziehen Sie einen Verwandten oder Freund ein <span className='font-medium'>und finden Sie die Zeit und Energie, um die Kraft und den Willen zurückzugewinnen, die Personen, die Sie betreuen, zu unterstützen</span>.
                </div>
                
            </div>
                :

                <div className=' -mt-1 pb-3 text-justify '>
                    <div className='flex  items-center space-x-5'>
                        <img src='/images/peoples.png' className='w-32' />
                        <div>
                            <h1 className='text-base sm:text-lg lg:text-xl font-bold text-md'> SIND SIE EINE ANGEHÖRIGE/EIN ANGEHÖRIGER?</h1>
                            <p className='font-light text-[14px] sm:text-[19px]'>Einfache Dinge, die Sie tun können</p>
                        </div>

                    </div>
                    <div className='-mt-4'>
                        <hr className=' bg-white  text-white' style={{ height: '1px' }} />
                    </div>
                    <div className='mt-8'>
                        Hier sind einige nützliche Vorschläge, die Sie beachten sollten, <span className='font-medium'>um Ihren Liebsten zu helfen, die durch ASTHMA auferlegten Grenzen zu überwinden</span>: </div>
                    <div>
                        Unterstützen Sie sie bei der Gewichtskontrolle, vermeiden Sie Rauch und fördern Sie körperliche Aktivität.

                    </div>

                    <div className='mt-4'>
                        Einer der wichtigsten Vorschläge betrifft jedoch Sie. <span className='font-medium'>Sorgen Sie für sich selbst, wann immer es möglich ist.</span> Nehmen Sie sich also Zeit, laden Sie Ihre inneren Batterien auf, bitten Sie um Hilfe, wenn nötig, und versuchen Sie, die Zeit und Energie zu finden, um neue Kraft zu schöpfen, <span className='font-medium'>damit Sie beide Ihre Ziele erreichen können.</span>
                    </div>
                </div>
            }
        </div>
    )
}

export default AreYouCareGiver